import React, { useEffect, useRef, useState } from 'react';
import {
  Bookmark as BookmarkIcon,
  Clock,
  Search as SearchIcon,
  X,
} from 'react-feather';
import { MeiliSearch } from 'meilisearch';
import { useNavigate } from 'react-router-dom';
import { hapticsImpactLight } from '../../../utils/haptics';
import { useHotkeys } from 'react-hotkeys-hook';
import { RootState } from '../../../store/store';
import { useSelector } from 'react-redux';
import { getBookmarks } from '../../../services/bookmarks';
import { Bookmark } from '../../../types/Bookmark';

const client = new MeiliSearch({
  host: 'https://ms-2894cc38327f-12358.sfo.meilisearch.io',
  apiKey: '8322acd1ebf9ad9a8ff4420430a4074ab437b6e0880fb932a2da4488885e9c1c',
});
const index = client.index('articles');

interface ArticleResult {
  articleUUID: string;
  articleTitle: string;
}

interface RecentArticle {
  articleId: string;
  articleTitle: string;
}

interface Item {
  articleId: string;
  articleTitle: string;
  isRecentArticle?: boolean;
  isBookmark?: boolean;
}

interface Section {
  label: string;
  items: Item[];
}

interface SearchProps {
  loadArticleById?: (articleId: string) => void;
  setMobileMenuIsOpen?: (isOpen: boolean) => void;
  isArticleBrowser: boolean;
}

const Search: React.FC<SearchProps> = ({
  loadArticleById,
  setMobileMenuIsOpen,
  isArticleBrowser,
}) => {
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);
  const [searchedWord, setSearch] = useState('');
  const [resultSearch, setResults] = useState<ArticleResult[]>([]);
  const [userBookmarks, setUserBookmarks] = useState<Bookmark[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [recentArticles, setRecentArticles] = useState<RecentArticle[]>([]);
  const navigate = useNavigate();
  const itemRefs = useRef<(HTMLButtonElement | null)[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);

  useHotkeys('/', (e) => {
    if (isArticleBrowser) return;
    e.preventDefault();
    const input = document.getElementById('library-search-input');
    if (input) {
      input.focus();
    }
  });

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setIsFocused(false);
        if (searchedWord) {
          setSearch('');
        }
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [searchedWord]);

  useEffect(() => {
    const bookmarkArticleIds = new Set(userBookmarks.map((b) => b.articleId));
    const filteredRecentArticles = recentArticles.filter(
      (a) => !bookmarkArticleIds.has(a.articleId)
    );

    const sections: Section[] = [];

    if (searchedWord) {
      const items = resultSearch.map((item) => ({
        articleId: item.articleUUID,
        articleTitle: item.articleTitle,
      }));
      sections.push({ label: '', items });
    } else if (isFocused) {
      if (filteredRecentArticles.length > 0) {
        sections.push({
          label: 'Recents',
          items: filteredRecentArticles.slice(0, 5).map((article) => ({
            articleId: article.articleId,
            articleTitle: article.articleTitle,
            isRecentArticle: true,
          })),
        });
      }
      if (userBookmarks.length > 0) {
        sections.push({
          label: 'Bookmarks',
          items: userBookmarks.map((bookmark) => ({
            articleId: bookmark.articleId,
            articleTitle: bookmark.articleTitle,
            isBookmark: true,
          })),
        });
      }
    }

    const flattenedItems = sections.flatMap((section) => section.items);

    const handleKeyDown = (event: KeyboardEvent) => {
      if (!flattenedItems.length) return;

      if (event.key === 'ArrowDown') {
        setSelectedIndex((prevIndex) =>
          prevIndex === null || prevIndex === flattenedItems.length - 1
            ? 0
            : prevIndex + 1
        );
      } else if (event.key === 'ArrowUp') {
        setSelectedIndex((prevIndex) =>
          prevIndex === null || prevIndex === 0
            ? flattenedItems.length - 1
            : prevIndex - 1
        );
      } else if (event.key === 'Enter' && selectedIndex !== null) {
        const selectedItem = flattenedItems[selectedIndex];
        handleItemClick(selectedItem);
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [
    selectedIndex,
    resultSearch,
    userBookmarks,
    loadArticleById,
    setMobileMenuIsOpen,
    navigate,
    isFocused,
    searchedWord,
    recentArticles,
  ]);

  useEffect(() => {
    if (selectedIndex !== null && itemRefs.current[selectedIndex]) {
      itemRefs.current[selectedIndex]?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [selectedIndex]);

  useEffect(() => {
    async function searchWithMeili() {
      if (searchedWord) {
        const search = await index.search(searchedWord);
        if (search && search.hits) {
          // @ts-expect-error - TS doesn't know about the hits property
          setResults(search.hits);
          setSelectedIndex(null);
        }
      } else {
        setResults([]);
      }
    }
    searchWithMeili();
  }, [searchedWord]);

  useEffect(() => {
    if (isFocused && !searchedWord) {
      loadBookmarks();
    } else {
      setSelectedIndex(null);
    }
  }, [currentUser, isFocused, searchedWord]);

  useEffect(() => {
    const storedRecentArticles = JSON.parse(
      localStorage.getItem('recentArticles') || '[]'
    );
    setRecentArticles(storedRecentArticles);
  }, []);

  const updateRecentArticles = (article: RecentArticle) => {
    const isBookmarked = userBookmarks.some(
      (b) => b.articleId === article.articleId
    );
    if (isBookmarked) return;

    const updatedArticles = [
      article,
      ...recentArticles.filter((a) => a.articleId !== article.articleId),
    ];
    if (updatedArticles.length > 5) {
      updatedArticles.splice(5);
    }
    setRecentArticles(updatedArticles);
    localStorage.setItem('recentArticles', JSON.stringify(updatedArticles));
  };

  const loadBookmarks = async () => {
    if (currentUser) {
      const response = await getBookmarks(currentUser.id);
      if (response) {
        setUserBookmarks(response);
      }
    }
  };

  const handleItemClick = (item: Item) => {
    if (loadArticleById) {
      loadArticleById(item.articleId);
      navigate(`/library/${item.articleId}`);
    } else {
      navigate(`/library/${item.articleId}`);
    }
    if (setMobileMenuIsOpen) {
      setMobileMenuIsOpen(false);
    }
    if (!item.isRecentArticle && !item.isBookmark) {
      updateRecentArticles({
        articleId: item.articleId,
        articleTitle: item.articleTitle,
      });
    }
    setSearch('');
    hapticsImpactLight();
    setIsFocused(false);
    const input = document.getElementById('library-search-input');
    if (input) {
      input.blur();
    }
  };

  const bookmarkArticleIds = new Set(userBookmarks.map((b) => b.articleId));
  const filteredRecentArticles = recentArticles.filter(
    (a) => !bookmarkArticleIds.has(a.articleId)
  );

  const sections: Section[] = [];

  if (searchedWord) {
    const items = resultSearch.map((item) => ({
      articleId: item.articleUUID,
      articleTitle: item.articleTitle,
    }));
    sections.push({ label: '', items });
  } else if (isFocused) {
    if (filteredRecentArticles.length > 0) {
      sections.push({
        label: 'Recents',
        items: filteredRecentArticles.slice(0, 5).map((article) => ({
          articleId: article.articleId,
          articleTitle: article.articleTitle,
          isRecentArticle: true,
        })),
      });
    }
    if (userBookmarks.length > 0) {
      sections.push({
        label: 'Bookmarks',
        items: userBookmarks.map((bookmark) => ({
          articleId: bookmark.articleId,
          articleTitle: bookmark.articleTitle,
          isBookmark: true,
        })),
      });
    }
  }

  const flattenedItems = sections.flatMap((section) => section.items);

  return (
    <div
      className={`search ${searchedWord ? 'has-query' : ''}`}
      ref={containerRef}
    >
      <div className="search_input-wrapper">
        <SearchIcon />
        <input
          id="library-search-input"
          type="text"
          value={searchedWord}
          onChange={(event) => setSearch(event.target.value)}
          placeholder="Search for a topic..."
          onFocus={() => setIsFocused(true)}
        />
        {!searchedWord && <div className="hint">/</div>}
        {searchedWord && (
          <button
            className="button button--clear-search"
            onClick={() => {
              setSearch('');
              hapticsImpactLight();
            }}
          >
            <X />
          </button>
        )}
      </div>
      {flattenedItems.length > 0 && (
        <div className="flyout">
          {sections.map((section, sectionIndex) => (
            <div key={sectionIndex}>
              {section.label && (
                <div className="section-label">{section.label}</div>
              )}
              {section.items.map((item, index) => {
                const overallIndex =
                  sections
                    .slice(0, sectionIndex)
                    .reduce((acc, sec) => acc + sec.items.length, 0) + index;
                return (
                  <button
                    key={item.articleId || overallIndex}
                    ref={(el) => (itemRefs.current[overallIndex] = el)}
                    onClick={() => handleItemClick(item)}
                    className={
                      overallIndex === selectedIndex
                        ? 'selected result-listing'
                        : 'result-listing'
                    }
                    style={{
                      backgroundColor:
                        overallIndex === selectedIndex
                          ? 'var(--surface-glass-light)'
                          : 'transparent',
                    }}
                  >
                    <h2
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '.5rem',
                      }}
                    >
                      {item.isRecentArticle && (
                        <Clock
                          style={{
                            flexShrink: 0,
                            alignSelf: 'flex-start',
                          }}
                        />
                      )}
                      {item.isBookmark && (
                        <BookmarkIcon
                          style={{
                            flexShrink: 0,
                            alignSelf: 'flex-start',
                          }}
                        />
                      )}
                      {item.articleTitle}
                    </h2>
                  </button>
                );
              })}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Search;
