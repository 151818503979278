export const OraMuiSlider = {
  styleOverrides: {
    root: {
      color: '#6CC2B2',
    },
    thumb: {
      color: '#6CC2B2',
    },
    track: {
      color: '#6CC2B2',
    },
    rail: {
      color: '#E3E4E8',
    },
    mark: {
      color: 'rgba(255,255,255,.3)',
      height: 8,
      width: 2,
      top: '1.5rem',
    },
    markLabel: {
      color: '#FEFEFE',
      fontSize: '0.75rem',
    },
  },
};
