export const OraMuiSwitch = {
  styleOverrides: {
    switchBase: {
      '&.Mui-checked': {
        color: '#6CC2B2',
        '& + .MuiSwitch-track': {
          backgroundColor: '#6CC2B2',
          opacity: 1,
        },
      },
    },
    thumb: {
      backgroundColor: '#FEFEFE !important',
    },
  },
};
