import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface StatsDataPayload {
  averageFlashcardTime: number | null;
  averageVignetteTime: number | null;
}

const initialState: StatsDataPayload = {
  averageFlashcardTime: null,
  averageVignetteTime: null,
};

export const statsSlice = createSlice({
  name: 'stats',
  initialState,
  reducers: {
    setStatsData: (_state, action: PayloadAction<StatsDataPayload>) => {
      return action.payload;
    },
  },
});

export const { setStatsData } = statsSlice.actions;

export default statsSlice.reducer;
