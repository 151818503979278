import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { RootState } from '../../../../store/store';
import LinearProgress from '@mui/material/LinearProgress';
import Skeleton from '@mui/material/Skeleton';
import parse from 'html-react-parser';
import {
  Check,
  Minus,
  MoreVertical,
  Plus,
  Repeat,
  Settings,
} from 'react-feather';
import { SortableItem } from './SortableItem';
import {
  calculateStudyProgress,
  calculateStudyTimeRemaining,
} from '../../../../utils/calculateStudyTime';
import { updateCurrentUserInfo } from '../../../../services/auth';
import { Block, StudyPhase } from '../../../../types/Study';
import { setUserInfo } from '../../../../slices/auth/authSlice';
import { updateStudySession } from '../../../../services/studySession';
import { setTodaySession } from '../../../../slices/todaySession/todaySessionSlice';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SnoozeSession from '../../SnoozeSession';
import { hapticsImpactLight } from '../../../../utils/haptics';
import { enqueueSnackbar } from 'notistack';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import { modifyStudySession } from '../../../../services/study';
import { useRefreshStudySessionAndContent } from '../../../../utils/refreshStudySession';
import { TriangleRight } from '../../../../assets/svgs/TriangleRight';
import { UserExam } from '../../../../types/User';

interface SessionOverviewProps {
  isSwitchingExam?: boolean;
  isModifyingToday: boolean;
  setIsModifyingToday: (isModifyingToday: boolean) => void;
  isExtendingSession: boolean;
  setIsExtendingSession: (isExtendingSession: boolean) => void;
}

const SessionOverview: React.FC<SessionOverviewProps> = ({
  isSwitchingExam,
  isModifyingToday,
  setIsModifyingToday,
  isExtendingSession,
  setIsExtendingSession,
}) => {
  const todaySession = useSelector((state: RootState) => state.todaySession);
  const flashcards = useSelector((state: RootState) => state.flashcards);
  const stats = useSelector((state: RootState) => state.stats);
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);
  const blocks = useSelector((state: RootState) => state.blocks);
  const activeBlockId = useSelector(
    (state: RootState) => state.todaySession.activeBlockId
  );
  const activeBlock = useSelector(
    (state: RootState) =>
      state.blocks.find((b: Block) => b.id === activeBlockId)!
  );
  const currentUserExams =
    useSelector((state: RootState) => state.auth.userInfo?.exams) || [];
  const primaryExam = currentUserExams.find((exam: UserExam) => exam.primary);
  const preferredPhase = useSelector(
    (state: RootState) => state.auth.userInfo?.preferredStartPhase
  );
  const [currentProgress, setCurrentProgress] = useState(0);
  const [items, setItems] = useState<string[]>([]);
  const [isDragging, setIsDragging] = useState(false);

  const [customFlashcardCount, setCustomFlashcardCount] = useState(0);
  const [customQBankCount, setCustomQBankCount] = useState(0);
  const [disableSave, setDisableSave] = useState(true);

  const [sessionMenuRef, setSessionMenuRef] =
    React.useState<null | HTMLElement>(null);
  const sessionMenuOpen = Boolean(sessionMenuRef);
  const handleSessionMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setSessionMenuRef(event.currentTarget);
  };
  const handleSessionMenuClose = () => {
    setSessionMenuRef(null);
  };

  const dispatch = useDispatch();
  const previousFirstItemRef = useRef<string | null>(null);

  const {
    isLoadingStudySessionAndContent,
    // isLoadedStudySessionAndContent,
    setIsLoadingStudySessionAndContent,
    setIsLoadedStudySessionAndContent,
    refreshStudySessionAndContent,
  } = useRefreshStudySessionAndContent();

  useEffect(() => {
    setCurrentProgress(
      calculateStudyProgress(todaySession, flashcards, blocks)
    );

    const updatedItems: string[] = [];

    if (primaryExam) {
      if (primaryExam.isUsingFlashcards || todaySession.flashcardCount > 0) {
        updatedItems.push('flashcard');
      }
      if (primaryExam.isUsingQbank || todaySession.vignetteCount > 0) {
        updatedItems.push('qbank');
      }
    }

    // Move preferred phase to the top if it exists
    if (preferredPhase) {
      const index = updatedItems.indexOf(preferredPhase.toLowerCase());
      if (index > 0) {
        updatedItems.splice(index, 1);
        updatedItems.unshift(preferredPhase.toLowerCase());
      }
    }

    setItems(updatedItems);
  }, [todaySession, blocks, primaryExam, preferredPhase, flashcards]);

  useEffect(() => {
    const updatePhases = async () => {
      if (items.length > 0) {
        const firstItem = items[0];
        if (previousFirstItemRef.current !== firstItem) {
          previousFirstItemRef.current = firstItem;
          await updateUserPreferredStartingPhase(
            StudyPhase[firstItem as keyof typeof StudyPhase]
          );
          await updateStudySessionPhase(
            StudyPhase[firstItem as keyof typeof StudyPhase]
          );
        }
      }
    };

    updatePhases();
  }, [items]);

  useEffect(() => {
    setCustomFlashcardCount(todaySession?.flashcardCount);
    setCustomQBankCount(todaySession?.vignetteCount);
  }, [todaySession, isModifyingToday]);

  // Handle the enabling/disabling of the save button
  useEffect(() => {
    if (isModifyingToday) {
      if (
        customFlashcardCount !== todaySession?.flashcardCount ||
        customQBankCount !== todaySession?.vignetteCount
      ) {
        setDisableSave(false);
      } else {
        setDisableSave(true);
      }
    }
  }, [todaySession, isModifyingToday, customFlashcardCount, customQBankCount]);

  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    setIsDragging(false);

    if (over && active.id !== over.id) {
      setItems((prevItems) => {
        const oldIndex = prevItems.indexOf(String(active.id));
        const newIndex = prevItems.indexOf(String(over.id));

        return arrayMove(prevItems, oldIndex, newIndex);
      });
      enqueueSnackbar('Phase order updated.', { autoHideDuration: 3000 });
    }
  };

  const updateUserPreferredStartingPhase = async (phase: StudyPhase) => {
    const updateUser = await updateCurrentUserInfo({
      preferredStartPhase: phase,
    });
    if (updateUser) {
      dispatch(setUserInfo(updateUser));
    }
  };

  const updateStudySessionPhase = async (phase: StudyPhase) => {
    if (
      todaySession.id &&
      !todaySession.isStarted &&
      todaySession.phase !== phase
    ) {
      const updatedSession = { ...todaySession, phase: phase };
      const response = await updateStudySession(
        todaySession.id,
        updatedSession
      );
      dispatch(setTodaySession(response));
    }
  };

  const renderFlashcardCount = () => {
    if (isSwitchingExam || isLoadingStudySessionAndContent) {
      return (
        <Skeleton
          sx={{ bgcolor: 'rgba(255,255,255,.05)', borderRadius: '.25rem' }}
          variant="rectangular"
          animation="wave"
        >
          <div>000</div>
        </Skeleton>
      );
    }

    return (
      <div className="counts-wrapper">
        <div className={`counts ${isModifyingToday ? 'is-modifying' : ''}`}>
          <span className="counts_completed">
            {todaySession?.flashcardCount -
              flashcards?.new.length -
              flashcards?.due.length}{' '}
            of
          </span>
          <span className="counts_total">{todaySession?.flashcardCount}</span>
        </div>
        {isModifyingToday && (
          <div className="question-count-input">
            <input
              className="question-number-input"
              type="number"
              min={todaySession?.completedFlashcardCount || 0}
              max={500}
              value={customFlashcardCount}
              onChange={(e) => setCustomFlashcardCount(Number(e.target.value))}
            />
            <div className="question-count-input_spinners">
              <Tooltip
                title={
                  customFlashcardCount >= 500
                    ? 'Flashcard count cannot be greater than 500.'
                    : ''
                }
              >
                <span>
                  <button
                    className="button button--glass"
                    onClick={() =>
                      handleSpinClick(
                        'flashcard',
                        'up',
                        customFlashcardCount,
                        todaySession?.completedFlashcardCount || 0,
                        500
                      )
                    }
                    disabled={customFlashcardCount >= 500}
                  >
                    <Plus />
                  </button>
                </span>
              </Tooltip>
              <Tooltip
                title={
                  customFlashcardCount === 0
                    ? ''
                    : customFlashcardCount <=
                        (todaySession?.completedFlashcardCount || 0)
                      ? 'Flashcard count cannot be less than the number of completed flashcards.'
                      : ''
                }
              >
                <span>
                  <button
                    className="button button--glass"
                    onClick={() =>
                      handleSpinClick(
                        'flashcard',
                        'down',
                        customFlashcardCount,
                        todaySession?.completedFlashcardCount || 0,
                        500
                      )
                    }
                    disabled={
                      customFlashcardCount <=
                      (todaySession?.completedFlashcardCount || 0)
                    }
                  >
                    <Minus />
                  </button>
                </span>
              </Tooltip>
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderQBankCount = (totalVignetteCount: number) => {
    if (isSwitchingExam || isLoadingStudySessionAndContent) {
      return (
        <Skeleton
          sx={{ bgcolor: 'rgba(255,255,255,.05)', borderRadius: '.25rem' }}
          variant="rectangular"
          animation="wave"
        >
          <div>000</div>
        </Skeleton>
      );
    }
    const modifyQBankMinimum = calcNumberOfStartedVignettes();
    return (
      <div className="counts-wrapper">
        <div className={`counts ${isModifyingToday ? 'is-modifying' : ''}`}>
          <span className="counts_completed">{totalVignetteCount} of</span>
          <span className="counts_total">{todaySession?.vignetteCount}</span>
        </div>
        {isModifyingToday && (
          <div className="question-count-input">
            <input
              className="question-number-input"
              type="number"
              min={modifyQBankMinimum}
              max={320}
              value={customQBankCount}
              onChange={(e) => setCustomQBankCount(Number(e.target.value))}
            />
            <div className="question-count-input_spinners">
              <Tooltip
                title={
                  customQBankCount >= 320
                    ? 'QBank count cannot be greater than 320.'
                    : ''
                }
              >
                <span>
                  <button
                    className="button button--glass"
                    onClick={() =>
                      handleSpinClick(
                        'qbank',
                        'up',
                        customQBankCount,
                        modifyQBankMinimum,
                        320
                      )
                    }
                    disabled={customQBankCount >= 320}
                  >
                    <Plus />
                  </button>
                </span>
              </Tooltip>
              <Tooltip
                title={
                  customQBankCount === 0
                    ? ''
                    : customQBankCount <= modifyQBankMinimum
                      ? 'QBank count cannot be less than the number of questions in started or submitted blocks.'
                      : ''
                }
              >
                <span>
                  <button
                    className="button button--glass"
                    onClick={() =>
                      handleSpinClick(
                        'qbank',
                        'down',
                        customQBankCount,
                        modifyQBankMinimum,
                        320
                      )
                    }
                    disabled={customQBankCount <= modifyQBankMinimum}
                  >
                    <Minus />
                  </button>
                </span>
              </Tooltip>
            </div>
          </div>
        )}
      </div>
    );
  };

  const calcNumberOfStartedVignettes = () => {
    let count = 0;
    for (let i = 0; i < blocks.length; i++) {
      if (blocks[i].isStarted) {
        count += blocks[i].vignetteCount;
      }
    }
    return count;
  };

  const renderMaximumsTooltip = () => {
    const messages = [];
    const modifyQBankMinimum = calcNumberOfStartedVignettes();
    if (customQBankCount > 320) {
      messages.push('QBank Daily Maximum: 320');
    }
    if (customQBankCount < modifyQBankMinimum) {
      messages.push(
        `QBank Minimum: ${modifyQBankMinimum} (Based on Started Block)`
      );
    }
    if (customFlashcardCount > 500) {
      messages.push('Flashcard Daily Maximum: 500');
    }
    if (customFlashcardCount < (todaySession?.completedFlashcardCount || 0)) {
      messages.push(
        `Flashcard Minimum: ${todaySession?.completedFlashcardCount || 0} (Based on Completed Flashcards)`
      );
    }
    if (messages.length === 0) {
      return null;
    }
    return (
      <div>
        {messages.map((message, index) => (
          <div key={index}>{message}</div>
        ))}
      </div>
    );
  };

  const renderSessionOverviewBottom = () => {
    if (isModifyingToday) {
      const modifyQBankMinimum = calcNumberOfStartedVignettes();
      return (
        <div className="session_modify-controls">
          <button
            className="button button--glass"
            onClick={() => {
              hapticsImpactLight();
              setIsModifyingToday(false);
              setIsExtendingSession(false);
            }}
            style={
              isLoadingStudySessionAndContent
                ? { opacity: '0', pointerEvents: 'none', visibility: 'hidden' }
                : {}
            }
          >
            Cancel
          </button>
          <Tooltip title={renderMaximumsTooltip()}>
            <span>
              <button
                className="button button--glass"
                onClick={() => {
                  handleSubmitCustomSession();
                  hapticsImpactLight();
                }}
                style={
                  isLoadingStudySessionAndContent
                    ? {
                        opacity: '0',
                        pointerEvents: 'none',
                        visibility: 'hidden',
                      }
                    : {}
                }
                disabled={
                  disableSave ||
                  customQBankCount > 320 ||
                  customFlashcardCount > 500 ||
                  customQBankCount < modifyQBankMinimum ||
                  customFlashcardCount <
                    (todaySession?.completedFlashcardCount || 0)
                }
              >
                Confirm
              </button>
            </span>
          </Tooltip>
        </div>
      );
    } else {
      return (
        <>
          <div className="session_progress">
            <div className="session_progress_bar">
              <LinearProgress
                className="progress-bar"
                sx={{ width: '100%', minWidth: '13.8rem' }}
                variant="determinate"
                color="success"
                value={currentProgress}
              />
            </div>
            <div className="time-estimate" style={{ paddingTop: '.25rem' }}>
              {parse(
                calculateStudyTimeRemaining(
                  todaySession,
                  blocks,
                  stats.averageFlashcardTime,
                  stats.averageVignetteTime
                )
              )}
              <span className="helper">Est. Remaining</span>
            </div>
          </div>
        </>
      );
    }
  };

  const renderFlashcardDisplay = () => (
    <>
      {flashcards.new.length + flashcards.due.length === 0 && (
        <Check className="check" />
      )}
      {todaySession?.phase === StudyPhase.flashcard &&
        flashcards.new.length + flashcards.due.length > 0 && (
          <Tooltip title={'Current phase'}>
            <div className="dot"></div>
          </Tooltip>
        )}
      <div className="label">Flashcards</div>
      {renderFlashcardCount()}
    </>
  );

  const renderQBankDisplay = () => {
    let totalVignetteCount = 0;
    for (let i = 0; i < blocks.length; i++) {
      if (blocks[i].isSubmitted) {
        totalVignetteCount += blocks[i].vignetteCount;
      } else {
        totalVignetteCount += Object.keys(blocks[i].answers).length;
      }
    }
    return (
      <div className="qbank-row">
        <div className="qbank-row_top">
          {totalVignetteCount === todaySession?.vignetteCount && (
            <Check className="check" />
          )}
          {todaySession?.phase === StudyPhase.qbank &&
            totalVignetteCount !== todaySession?.vignetteCount && (
              <Tooltip title={'Current phase'}>
                <div className="dot"></div>
              </Tooltip>
            )}
          <div className="label">QBank</div>
          {renderQBankCount(totalVignetteCount)}
        </div>
        <div className="qbank-row_bottom">
          {currentUser && (
            <Select
              labelId="block-type-selector-label"
              id="block-type-selector"
              value={currentUser.preferredBlockType}
              onChange={(e) => {
                const newValue = e.target.value;
                handleBlockTypeChange(newValue);
              }}
              onClick={hapticsImpactLight}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
              }}
            >
              <MenuItem value="tutor">
                <TriangleRight />
                Untimed
              </MenuItem>
              <MenuItem value="timed">
                <TriangleRight />
                Timed
              </MenuItem>
            </Select>
          )}
        </div>
      </div>
    );
  };

  const handleBlockTypeChange = async (blockType: string) => {
    const updateUser = await updateCurrentUserInfo({
      preferredBlockType: blockType,
    });
    if (updateUser) {
      dispatch(setUserInfo(updateUser));
    }
    let messageText = '';
    if (activeBlock?.isStarted) {
      messageText =
        'Block type updated. This change will take effect on the next block.';
    } else {
      messageText = 'Block type updated.';
    }
    enqueueSnackbar(messageText, { autoHideDuration: 5000 });
  };

  const handleSubmitCustomSession = async () => {
    setIsLoadingStudySessionAndContent(true);
    setIsLoadedStudySessionAndContent(false);
    await modifyStudySession(
      todaySession.id,
      customFlashcardCount,
      customQBankCount
    );
    await refreshStudySessionAndContent();
    setIsLoadingStudySessionAndContent(false);
    setIsLoadedStudySessionAndContent(true);
    setIsModifyingToday(false);
    setIsExtendingSession(false);
  };

  const handleSwitchPhases = () => {
    if (items.length > 1) {
      setItems((prevItems) => {
        const newItems = [...prevItems];
        [newItems[0], newItems[1]] = [newItems[1], newItems[0]];
        return newItems;
      });
      enqueueSnackbar('Phase order updated.', { autoHideDuration: 3000 });
    }
  };

  const handleSpinClick = (
    type: 'qbank' | 'flashcard',
    direction: 'up' | 'down',
    currentValue: number,
    min: number,
    max: number
  ) => {
    if (direction === 'up') {
      if (type === 'flashcard') {
        if (currentValue + 1 > max) {
          setCustomFlashcardCount(max);
        } else {
          setCustomFlashcardCount(currentValue + 1);
        }
      } else {
        if (currentValue + 1 > max) {
          setCustomQBankCount(max);
        } else {
          setCustomQBankCount(currentValue + 1);
        }
      }
    } else {
      if (type === 'flashcard') {
        if (currentValue - 1 < min) {
          setCustomFlashcardCount(min);
        } else {
          setCustomFlashcardCount(currentValue - 1);
        }
      } else {
        if (currentValue - 1 < min) {
          setCustomQBankCount(min);
        } else {
          setCustomQBankCount(currentValue - 1);
        }
      }
    }
  };

  const topItem = items[0];
  const switchPhasesButtonClass = `button button--glass button--switch-phases ${
    topItem === 'qbank' ? 'button--switch-phases--qbank' : ''
  }`;

  return (
    <>
      <div
        className={`session${isModifyingToday ? ' is-editing' : ''}${isExtendingSession ? ' is-extending' : ''}`}
      >
        <div className="session_top">
          <h3>
            {isModifyingToday ? "Modify Today's Session" : "Today's Session"}
          </h3>
          {!isModifyingToday && (
            <button
              id="session-menu-button"
              className="button button--icon-only button--session-menu"
              aria-controls={sessionMenuOpen ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={sessionMenuOpen ? 'true' : undefined}
              onClick={(e) => {
                handleSessionMenuClick(e);
                hapticsImpactLight();
              }}
            >
              <MoreVertical />
            </button>
          )}
          <button
            className="button button--icon-only button--modify"
            onClick={() => {
              setIsModifyingToday(true);
              hapticsImpactLight();
            }}
            style={
              isModifyingToday
                ? { opacity: '0', pointerEvents: 'none', visibility: 'hidden' }
                : { marginLeft: 'auto' }
            }
          >
            <Settings />
          </button>
        </div>
        <div className={`session_schedule ${isDragging ? 'is-dragging' : ''}`}>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={items}
              strategy={verticalListSortingStrategy}
            >
              {items.map((item, index) => {
                return (
                  <SortableItem
                    key={item}
                    id={item}
                    index={index}
                    only={items.length === 1}
                  >
                    {item === 'flashcard'
                      ? renderFlashcardDisplay()
                      : renderQBankDisplay()}
                  </SortableItem>
                );
              })}
            </SortableContext>
          </DndContext>
          {items.length !== 1 && (
            <button
              className={switchPhasesButtonClass}
              onClick={() => {
                hapticsImpactLight();
                handleSwitchPhases();
              }}
            >
              <Repeat />
              Switch Order
            </button>
          )}
        </div>
        {renderSessionOverviewBottom()}
        <Menu
          className="session-menu"
          id="session-menu"
          anchorEl={sessionMenuRef}
          open={sessionMenuOpen}
          onClose={handleSessionMenuClose}
          MenuListProps={{
            'aria-labelledby': 'session-menu-button',
          }}
        >
          <button
            className="session-menu-button"
            onClick={() => {
              handleSessionMenuClose();
              hapticsImpactLight();
              setIsModifyingToday(true);
            }}
          >
            <Settings />
            Modify Session
          </button>
          <div
            className="session-menu-button"
            onClick={() => {
              handleSessionMenuClose();
              hapticsImpactLight();
            }}
          >
            <SnoozeSession />
          </div>
        </Menu>
      </div>
    </>
  );
};

export default SessionOverview;
