import React, { useRef, useEffect, useState } from 'react';
import VideoSource from '../../../assets/videos/welcomev2.mp4';
import VideoThumbnail from '../../../assets/images/welcome-thumbnail.jpg';
import { PlayCircle, X } from 'react-feather';
import { hapticsImpactLight } from '../../../utils/haptics';
import { updateCurrentUserInfo } from '../../../services/auth';
import { setUserInfo } from '../../../slices/auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import CustomModal from '../../Global/CustomModal';
import VideoGif from '../../../assets/videos/welcome.gif';
import { RootState } from '../../../store/store';

const VideoPlayer: React.FC<{
  setIsWatched: (watched: boolean) => void;
  setHasWatchedHalf?: (watched: boolean) => void;
}> = ({ setIsWatched, setHasWatchedHalf }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [playbackRate, setPlaybackRate] = useState<number>(1);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const handleEnded = () => {};

    // Add event listeners
    video.addEventListener('ended', handleEnded);

    // Cleanup event listeners on component unmount
    return () => {
      video.removeEventListener('ended', handleEnded);
    };
  }, [setIsWatched, setHasWatchedHalf]);

  const changeSpeed = (speed: number) => {
    const video = videoRef.current;
    if (video) {
      video.playbackRate = speed;
      setPlaybackRate(speed);
    }
  };

  return (
    <div className="video-player">
      <video
        id="welcome-video"
        ref={videoRef}
        width="100%"
        height="auto"
        style={{ maxWidth: '100%', borderRadius: '.5rem' }}
        controls
        poster={VideoThumbnail}
      >
        <source src={VideoSource} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="video-player_playback">
        <button
          className={`button button--speed-option ${playbackRate === 1 ? 'is-active' : ''}`}
          onClick={() => changeSpeed(1)}
        >
          1x
        </button>
        <button
          className={`button button--speed-option ${playbackRate === 1.5 ? 'is-active' : ''}`}
          onClick={() => changeSpeed(1.5)}
        >
          1.5x
        </button>
        <button
          className={`button button--speed-option ${playbackRate === 2 ? 'is-active' : ''}`}
          onClick={() => changeSpeed(2)}
        >
          2x
        </button>
      </div>
    </div>
  );
};

interface WelcomeVideoProps {
  setIsWatched: (watched: boolean) => void;
  setJustShowedTip?: (showed: boolean) => void;
  showHomeTour: boolean;
}

const WelcomeVideo: React.FC<WelcomeVideoProps> = ({
  setIsWatched,
  setJustShowedTip,
  showHomeTour,
}) => {
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);
  const [hasOpenedVideo, setHasOpenedVideo] = useState(false);
  const [showVideoButton, setShowVideoButton] = useState(false);
  const [videoModalIsOpen, setVideoModalIsOpen] = useState(false);

  useEffect(() => {
    if (
      currentUser &&
      !currentUser.hasWatchedWelcomeVideo &&
      currentUser.onboardingTips['home-tour'] &&
      !showHomeTour
    ) {
      setShowVideoButton(true);
    }
  }, [currentUser, showHomeTour]);

  const dispatch = useDispatch();

  const handleWatchedVideo = async () => {
    setJustShowedTip && setJustShowedTip(true);
    const updateUser = await updateCurrentUserInfo({
      hasWatchedWelcomeVideo: true,
    });
    if (updateUser) {
      dispatch(setUserInfo(updateUser));
    }
  };

  return (
    <>
      {showVideoButton && !hasOpenedVideo && (
        <div className={`welcome-video ${videoModalIsOpen ? 'slide-out' : ''}`}>
          <button
            className="welcome-video_button"
            onClick={() => {
              setVideoModalIsOpen(true);
              hapticsImpactLight();
              handleWatchedVideo();
              setHasOpenedVideo(true);
            }}
          >
            <PlayCircle />
            <div className="welcome-video_button_content">
              <div className="welcome-video_button_content_top">
                Learn how Ora works
              </div>
              <div className="welcome-video_button_content_bottom">
                1min 24s
              </div>
            </div>
            {/* <div className="overlay-2"></div> */}
            <div className="overlay"></div>
            <img src={VideoGif} alt="Ora Welcome Video" />
          </button>
          <button
            className="welcome-video_close"
            onClick={(e) => {
              e.stopPropagation();
              handleWatchedVideo();
              setHasOpenedVideo(true);
            }}
          >
            <X />
          </button>
        </div>
      )}
      {videoModalIsOpen && (
        <CustomModal
          open={videoModalIsOpen}
          onClose={() => setVideoModalIsOpen(false)}
          name={'welcome-video'}
          glass={true}
        >
          <div className="modal_content">
            <div className="video-wrapper">
              <VideoPlayer setIsWatched={setIsWatched} />
            </div>
          </div>
        </CustomModal>
      )}
    </>
  );
};

export default WelcomeVideo;
