import { useEffect, useState, useRef } from 'react';
import { RootState } from '../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAllExamSessions,
  updateStudySession,
} from '../../../services/studySession';
import moment from 'moment';
import { StudySession } from '../../../types/Study';
import { fetchTopicPriorities } from '../../../services/stats';
import ReactDOM from 'react-dom';
import { Topic } from '../../../types/Dashboard';
import CircleProgress from '../../CustomMUI/CircleProgress';
import { LinkConnector } from '../../../assets/svgs/LinkConnector';
import { ArrowLeft, X } from 'react-feather';
import { useHotkeys } from 'react-hotkeys-hook';
import { posthog } from 'posthog-js';
import { setTodaySession } from '../../../slices/todaySession/todaySessionSlice';
import { hapticsImpactLight } from '../../../utils/haptics';
import ExamDateSelect from '../../Global/Header/ExamDateSelect';

interface StudyDay {
  completedFlashcardCount: number;
  date: string;
  flashcardCount: number;
  isComplete: boolean;
  isPast: boolean;
  isToday: boolean;
  isFuture: boolean;
  isSnoozed: boolean;
  isStarted: boolean;
  vignetteCorrectCount: number;
  vignetteCount: number;
  vignetteIncorrectCount: number;
  vignetteOmittedCount: number;
}

interface PriorityDisplayProps {
  containerRef: React.RefObject<HTMLDivElement>;
  showTopicPriorities: boolean;
  setShowTopicPriorities: (show: boolean) => void;
  setIsLoadingStudySessionAndContent: (loading: boolean) => void;
  setIsLoadedStudySessionAndContent: (loaded: boolean) => void;
  refreshStudySessionAndContent: () => void;
}

const PriorityDisplay: React.FC<PriorityDisplayProps> = ({
  containerRef,
  showTopicPriorities,
  setShowTopicPriorities,
  setIsLoadingStudySessionAndContent,
  setIsLoadedStudySessionAndContent,
  refreshStudySessionAndContent,
}) => {
  const currentUserExams =
    useSelector((state: RootState) => state.auth.userInfo?.exams) || [];
  const primaryExam = currentUserExams.find((exam) => exam.primary);
  const activeTopics = useSelector((state: RootState) => state.activeTopics);
  const todaySession = useSelector((state: RootState) => state.todaySession);
  const [filledStudyDays, setFilledStudyDays] = useState<StudyDay[]>([]);
  const [topicPriorities, setTopicPriorities] = useState<Topic[]>([]);

  const [selectedTopic, setSelectedTopic] = useState<Topic | null>(null);
  const [isMobileSelected, setIsMobileSelected] = useState(false);

  const priorityDisplayRef = useRef<HTMLDivElement>(null);
  const priorityDisplayContainerRef = useRef<HTMLDivElement>(null);
  const chartRef = useRef<HTMLDivElement | null>(null);
  const todayRef = useRef<HTMLDivElement | null>(null);

  const dispatch = useDispatch();

  useHotkeys('escape', () => keybinds('esc'), { keyup: true });

  const keybinds = (key: string, event?: KeyboardEvent) => {
    if (event) {
      event.preventDefault();
    }
    if (key === 'esc') {
      setShowTopicPriorities(false);
    }
  };

  useEffect(() => {
    if (showTopicPriorities) {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          priorityDisplayContainerRef.current &&
          !priorityDisplayContainerRef.current.contains(event.target as Node)
        ) {
          setTimeout(() => {
            setShowTopicPriorities(false);
          }, 100);
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [showTopicPriorities]);

  useEffect(() => {
    if (primaryExam && primaryExam.isUsingQbank) {
      createAndFillPastStudyDays();
      getPriorityTopics();
    }
  }, [primaryExam, activeTopics, todaySession]);

  // Scroll the chart to the today element
  useEffect(() => {
    if (todayRef.current && chartRef.current) {
      const todayElement = todayRef.current;
      const chartElement = chartRef.current;
      const todayRect = todayElement.getBoundingClientRect();
      const todayWidth = todayRect.width;
      const currentScrollLeft = chartElement.scrollLeft;
      const viewportCenterX = window.innerWidth / 2;
      const todayCenterX = todayRect.left + todayWidth / 2;
      const scrollDifference = todayCenterX - viewportCenterX;
      const newScrollLeft = currentScrollLeft + scrollDifference;
      chartElement.scrollLeft = newScrollLeft;
    }
  }, [filledStudyDays, window.innerWidth]);

  // Mark that the user has seen the priorities for today
  useEffect(() => {
    if (
      showTopicPriorities &&
      todaySession &&
      !todaySession.hasSeenPriorities
    ) {
      handleSeenPriorities();
    }
  }, [showTopicPriorities, todaySession]);

  const createAndFillPastStudyDays = async () => {
    if (primaryExam) {
      try {
        const response = await fetchAllExamSessions(primaryExam.id);
        if (response) {
          const existingStudyDays: StudyDay[] = response.map(
            (session: StudySession) => {
              const formattedDate = moment(session.date).format('YYYY-MM-DD');
              const { isPast, isToday, isFuture } =
                getDateStatus(formattedDate);
              return {
                ...session,
                date: formattedDate,
                isPast,
                isToday,
                isFuture,
              };
            }
          );
          const completeStudyDays = fillMissingStudyDays(
            existingStudyDays,
            moment(primaryExam.examDate).format('YYYY-MM-DD')
          );
          setFilledStudyDays(completeStudyDays);
        }
      } catch (error) {
        console.error('Error fetching exam sessions:', error);
      }
    }
  };

  const getAllDates = (startDate: string, endDate: string): string[] => {
    const dates: string[] = [];
    let currentDate = moment(startDate);
    const lastDate = moment(endDate);
    while (currentDate.isSameOrBefore(lastDate, 'day')) {
      dates.push(currentDate.format('YYYY-MM-DD'));
      currentDate = currentDate.add(1, 'day');
    }
    return dates;
  };

  const getDateStatus = (
    dateString: string
  ): { isPast: boolean; isToday: boolean; isFuture: boolean } => {
    const date = moment(dateString, 'YYYY-MM-DD');
    const today = moment().startOf('day');
    const isToday = date.isSame(today, 'day');
    const isPast = date.isBefore(today, 'day');
    const isFuture = date.isAfter(today, 'day');
    return { isPast, isToday, isFuture };
  };

  const fillMissingStudyDays = (
    existingStudyDays: StudyDay[],
    examDate: string
  ): StudyDay[] => {
    if (existingStudyDays.length === 0) {
      console.warn(
        'No existing study days found. Filling all days with default values.'
      );
    }
    const existingDates = existingStudyDays.map((sd) => sd.date);
    const startDate =
      existingStudyDays.length > 0
        ? moment.min(existingStudyDays.map((sd) => moment(sd.date)))
        : moment(examDate).startOf('day');
    const endDate = moment(examDate).subtract(1, 'day').startOf('day');
    const allDates = getAllDates(
      startDate.format('YYYY-MM-DD'),
      endDate.format('YYYY-MM-DD')
    );
    const existingDateSet = new Set(existingDates);
    const defaultStudyDay: Omit<
      StudyDay,
      'date' | 'isPast' | 'isToday' | 'isFuture'
    > = {
      completedFlashcardCount: 0,
      flashcardCount: 0,
      isComplete: false,
      isSnoozed: false,
      isStarted: false,
      vignetteCorrectCount: 0,
      vignetteCount: 0,
      vignetteIncorrectCount: 0,
      vignetteOmittedCount: 0,
    };
    const filledStudyDays: StudyDay[] = allDates.map((date) => {
      const { isPast, isToday, isFuture } = getDateStatus(date);
      if (existingDateSet.has(date)) {
        const existingDay = existingStudyDays.find((sd) => sd.date === date);
        if (existingDay) {
          return {
            ...existingDay,
            isPast,
            isToday,
            isFuture,
          };
        }
      }
      return {
        date,
        ...defaultStudyDay,
        isPast,
        isToday,
        isFuture,
      };
    });
    return filledStudyDays;
  };

  const getPriorityTopics = async () => {
    if (!primaryExam || !todaySession.id) return;
    fetchTopicPriorities(primaryExam.id, todaySession.id).then((response) => {
      setTopicPriorities(response);
      if (response && response.length > 0) {
        setSelectedTopic(response[0]);
      }
    });
  };

  const isBufferDay = (studyDay: StudyDay) => {
    const examDate = moment(primaryExam?.examDate);
    const studyDate = moment(studyDay.date);
    const daysUntilExam = examDate.diff(studyDate, 'days');
    if (
      (daysUntilExam <= 14 &&
        (primaryExam?.name === 'USMLE Step 1' ||
          primaryExam?.name === 'USMLE Step 2')) ||
      (daysUntilExam <= 7 &&
        primaryExam?.name !== 'USMLE Step 1' &&
        primaryExam?.name !== 'USMLE Step 2')
    ) {
      return true;
    } else {
      return false;
    }
  };

  const determineMainLabel = (studyDay: StudyDay) => {
    if (studyDay.isToday) {
      return (
        <>
          <span className="default">
            <span>Today</span>
          </span>
          <span className="score">
            {Math.round(
              (studyDay.vignetteCorrectCount / studyDay.vignetteCount) * 100
            ) + '%'}
          </span>
        </>
      );
    }
    if (studyDay.isFuture) {
      if (isBufferDay(studyDay)) {
        return 'Buffer Day';
      } else {
        return 'Future Day';
      }
    }
    if (studyDay.isPast) {
      if (studyDay.isSnoozed) {
        return 'Snoozed';
      }
      if (studyDay.isStarted) {
        return (
          Math.round(
            (studyDay.vignetteCorrectCount / studyDay.vignetteCount) * 100
          ) + '%'
        );
      } else {
        if (isBufferDay(studyDay)) {
          return 'Buffer Day';
        } else {
          return 'Not Started';
        }
      }
    }
  };

  const handleSeenPriorities = async () => {
    const updatedSession = { ...todaySession, hasSeenPriorities: true };
    const response = await updateStudySession(todaySession.id, updatedSession);
    posthog?.capture('viewed_priorities');
    dispatch(setTodaySession(response));
  };

  return (
    <>
      {primaryExam?.isUsingQbank && (
        <div
          className="priority-display"
          id="priority-display"
          ref={priorityDisplayRef}
        >
          <div className="priority-display_chart" ref={chartRef}>
            {filledStudyDays.map((studyDay, index) => {
              let score = 0;
              if (studyDay.isPast && studyDay.isStarted) {
                score = studyDay.vignetteCorrectCount / studyDay.vignetteCount;
              }
              const barHeight = score * 1.5 + 0.5;
              return (
                <div
                  key={'studyDay-' + index}
                  className="study-day"
                  ref={(el) => {
                    if (studyDay.isToday) {
                      todayRef.current = el;
                    }
                  }}
                >
                  <div
                    className={`study-day_day-label${index < 6 ? ' first-sessions' : ''}${studyDay.isToday ? ' is-today' : ''}${studyDay.isToday && !todaySession.hasSeenPriorities ? ' not-viewed' : ''}`}
                  >
                    <div className="study-day_day-label_main">
                      {determineMainLabel(studyDay)}
                    </div>
                    <div className="study-day_day-label_date">
                      {moment(studyDay.date).format('MMMM DD, YYYY')}
                    </div>
                    <div className="triangle"></div>
                  </div>
                  <div
                    className={`study-day_day
                        ${studyDay.isToday ? ' is-today' : ''}
                        ${studyDay.isFuture ? ' is-future' : ''}
                        ${!studyDay.isStarted && !studyDay.isToday ? ' is-missing' : ''}
                        ${studyDay.isStarted ? ' is-started' : ''}
                        ${isBufferDay(studyDay) && !studyDay.isStarted ? ' is-buffer' : ''}
                      `}
                  >
                    <div
                      className={`study-day_day_score`}
                      style={
                        !studyDay.isFuture
                          ? {
                              height: `${studyDay.isToday && !studyDay.isStarted ? 0 : barHeight}rem`,
                            }
                          : { height: '2rem' }
                      }
                    ></div>
                  </div>
                  {studyDay.date.split('-')[2] === '01' ? (
                    <span className="study-day_month-label">
                      {moment(studyDay.date).format('MMM')}
                    </span>
                  ) : null}
                </div>
              );
            })}
          </div>
          <div className="exam-day">
            <ExamDateSelect
              setIsLoadingStudySessionAndContent={
                setIsLoadingStudySessionAndContent
              }
              setIsLoadedStudySessionAndContent={
                setIsLoadedStudySessionAndContent
              }
              refreshStudySessionAndContent={refreshStudySessionAndContent}
            />
          </div>
        </div>
      )}
      {showTopicPriorities &&
        containerRef.current &&
        ReactDOM.createPortal(
          <div
            className="priority-display-container"
            ref={priorityDisplayContainerRef}
          >
            <div className="priority-display-container_content">
              <div className="priority-display-container_content_header">
                <h2>Today's Priority Topics</h2>
                <button
                  className="button button--icon-only button--close"
                  onClick={() => {
                    setShowTopicPriorities(false);
                    hapticsImpactLight();
                  }}
                >
                  <X />
                </button>
              </div>
              <div
                className={`priority-chart ${isMobileSelected ? 'mobile-is-open' : ''}`}
              >
                {topicPriorities && topicPriorities.length > 0 && (
                  <>
                    <div className="priority-chart_list">
                      <ol className="priority-chart_list_container">
                        {topicPriorities.map((topic: Topic, index) => (
                          <li
                            key={'topic-' + index}
                            className={`priority-chart_list_item ${
                              selectedTopic?.id === topic.id
                                ? 'is-selected'
                                : ''
                            }`}
                            onClick={() => {
                              setSelectedTopic(topic);
                              hapticsImpactLight();
                              setIsMobileSelected(true);
                            }}
                            role="button"
                            tabIndex={0}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter' || e.key === ' ') {
                                setSelectedTopic(topic);
                              }
                            }}
                          >
                            <div className="priority-chart_list_item_content">
                              <div> {index + 1}.</div>
                              <div>
                                {topic.path?.split('::')[0] && (
                                  <div className="priority-chart_list_item_content_level-one">
                                    {topic.path?.split('::')[0]}
                                  </div>
                                )}
                                <div> {topic.name}</div>
                              </div>
                            </div>
                            <CircleProgress value={topic.priority || 0} />
                          </li>
                        ))}
                      </ol>
                    </div>
                    <div className="priority-chart_details">
                      <button
                        className="button button--icon-only button--close"
                        onClick={() => {
                          setIsMobileSelected(false);
                          setSelectedTopic(null);
                          hapticsImpactLight();
                        }}
                      >
                        <ArrowLeft />
                      </button>
                      {selectedTopic ? (
                        <div>
                          <h2 className="priority-number">
                            {selectedTopic.priority}
                          </h2>
                          <h3 className="topic-name">
                            {selectedTopic.name || selectedTopic.topic}
                          </h3>
                          <p className="topic-path">
                            {selectedTopic.path?.split('::')[0]}
                          </p>
                          <div className="connector">
                            <LinkConnector />
                          </div>
                          <div className="topic-details">
                            <div className="metric">
                              {selectedTopic.yield !== null &&
                                selectedTopic.yield !== undefined && (
                                  <>
                                    <CircleProgress
                                      value={selectedTopic.yield || 0}
                                    />
                                    <p>Yield</p>
                                  </>
                                )}
                            </div>
                            <div className="metric metric--score">
                              {selectedTopic.score !== null &&
                              selectedTopic.score !== undefined ? (
                                <>
                                  <CircleProgress
                                    value={selectedTopic.score || 0}
                                    percentage={true}
                                  />
                                  <p>Score</p>
                                </>
                              ) : (
                                <>
                                  <CircleProgress
                                    value={selectedTopic.score || 0}
                                    percentage={true}
                                  />
                                  <p>Score</p>
                                  <p className="sub">No data yet</p>
                                </>
                              )}
                            </div>
                            <div className="metric">
                              {selectedTopic.percentUnseen !== null &&
                                selectedTopic.percentUnseen !== undefined && (
                                  <>
                                    <CircleProgress
                                      value={
                                        100 - selectedTopic.percentUnseen || 0
                                      }
                                      percentage={true}
                                    />
                                    <p>Completion</p>
                                  </>
                                )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <p>Select a topic to see details</p>
                      )}
                    </div>
                  </>
                )}
                {(!topicPriorities || topicPriorities.length === 0) && (
                  <div className="priority-chart_list">
                    <p
                      style={{
                        textAlign: 'center',
                        padding: '3rem',
                        color: 'var(--type-body-inverse)',
                      }}
                    >
                      No topics found for today.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>,
          containerRef.current
        )}
    </>
  );
};

export default PriorityDisplay;
