import React, { useEffect, useRef, useState } from 'react';
import { Copy, Link } from 'react-feather';
import { Flashcard as FlashcardType } from '../../../../types/Flashcard';
import {
  CircularProgress,
  InputLabel,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material';
import parse from 'html-react-parser';
import { useHotkeys } from 'react-hotkeys-hook';
import { hapticsImpactLight } from '../../../../utils/haptics';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { ResponseOption } from '../../../../types/User';
import { updateCurrentUserInfo } from '../../../../services/auth';
import { enqueueSnackbar } from 'notistack';
import { setUserInfo } from '../../../../slices/auth/authSlice';
import useWindowWidth from '../../../../hooks/useWindowWidth';
import { suspendFlashcard } from '../../../../services/flashcards';
import {
  removeFromNew,
  removeFromDue,
} from '../../../../slices/flashcards/flashcardsSlice';
import StudyPanel from '../../StudyPanel';
import { PanelType, StudyPhase } from '../../../../types/Study';
import ReportCard from '../../ReportCard';
import { removeFromPast } from '../../../../slices/pastFlashcards/pastFlashcardsSlice';
import { incrementCompletedFlashcards } from '../../../../slices/todaySession/todaySessionSlice';
// import EditFlashcard from '../../EditFlashcard';
import { copyToClipboard } from '../../../../utils/copyToClipboard';
import OraSwitch from '../../../CustomMUI/Switch';

import { useGamepadContext } from '../../../../context/useGamepadContext';
import CustomModal from '../../../Global/CustomModal';
import { GamepadButtonIcon } from '../../../Gamepad/GamepadButtonIcon';
import { GamepadButtonEventWithLogical } from '../../../../context/GamepadContext';
import FlashcardMenu from '../FlashcardMenu';
import ImageDisplay from '../../../Misc/ImageDisplay';

interface FlashcardProps {
  flashcard: FlashcardType | null;
  isFlipped: boolean;
  flipCard: (isFlipped: boolean) => void;
  handleResponse: (response: number) => void;
  newScheduling: { [key: number]: string };
  flashcardMenuIsOpen: boolean;
  setFlashcardMenuIsOpen: (isOpen: boolean) => void;
  prevFlashcard: boolean;
  undoResponse: () => void;
  shortcutMenuIsOpen: boolean;
  setShortcutMenuIsOpen: (isOpen: boolean) => void;
  keybindsDisabled: boolean;
  setKeybindsDisabled: (isDisabled: boolean) => void;
  isDiagnostic: boolean;
  panelType: PanelType;
  setPanelType: (panelType: PanelType) => void;
  fetchSuspendedFlashcards: () => void;
  setShowSuspendedCards: (showSuspendedCards: boolean) => void;
  suspendedCardCount: number;
}

const Flashcard: React.FC<FlashcardProps> = ({
  flashcard,
  isFlipped,
  flipCard,
  handleResponse,
  newScheduling,
  prevFlashcard,
  undoResponse,
  keybindsDisabled,
  setKeybindsDisabled,
  isDiagnostic,
  panelType,
  setPanelType,
  fetchSuspendedFlashcards,
  setShowSuspendedCards,
  suspendedCardCount,
}) => {
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);
  const [activeFlashcardVariant, setActiveFlashcardVariant] = useState(
    flashcard?.contents[flashcard.scheduling.difficultyIndex]
  );
  const [flashcardSettingsIsOpen, setFlashcardSettingsIsOpen] = useState(false);
  const [reportIsOpen, setReportIsOpen] = useState(false);
  const [speedDialIsOpen, setSpeedDialIsOpen] = useState(false);
  const [reportValue, setReportValue] = useState('');
  // const [isEditing, setIsEditing] = useState(false);
  const [responseOptions, setResponseOptions] = useState(
    currentUser?.responseOptions || 'two'
  );
  const [flashcardTextSize, setFlashcardTextSize] = useState(
    currentUser?.flashcardTextSize || 'default'
  );
  const [showIntervals, setShowIntervals] = useState(
    currentUser?.showIntervals || false
  );

  const dispatch = useDispatch();
  const windowWidth = useWindowWidth();

  useHotkeys('1', () => keybinds('1'), { keyup: true });
  useHotkeys('2', () => keybinds('2'), { keyup: true });
  useHotkeys('3', () => keybinds('3'), { keyup: true });
  useHotkeys('4', () => keybinds('4'), { keyup: true });
  useHotkeys('r', () => keybinds('r'), { keyup: true });
  useHotkeys('l', () => keybinds('l'), { keyup: true });
  useHotkeys('e', () => keybinds('e'), { keyup: true });
  useHotkeys('comma', () => keybinds('comma'), { keyup: true });
  useHotkeys('period', () => keybinds('period'), { keyup: true });
  useHotkeys('escape', () => keybinds('esc'), { keyup: true });
  useHotkeys('backspace', () => keybinds('backspace'), { keyup: true });
  useHotkeys('delete', () => keybinds('backspace'), { keyup: true });

  // Gamepad
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const {
    lastButtonEvent,
    gamepadType,
    setScrollTarget,
    setIsSelectGamepadModalOpen,
  } = useGamepadContext();
  const lastHandledButtonEventRef =
    useRef<GamepadButtonEventWithLogical | null>(null);
  useEffect(() => {
    if (
      lastButtonEvent &&
      lastButtonEvent.pressed &&
      lastButtonEvent !== lastHandledButtonEventRef.current
    ) {
      lastHandledButtonEventRef.current = lastButtonEvent;

      const { universalButton } = lastButtonEvent;
      if (!universalButton) {
        return;
      }
      switch (universalButton) {
        case 'A':
          if (isFlipped) {
            handleResponse(2);
          } else {
            flipCard(true);
          }
          break;
        case 'B':
          if (responseOptions === 'four' && isFlipped) {
            handleResponse(3);
          }
          break;
        case 'X':
          if (isFlipped) {
            handleResponse(1);
          }
          break;
        case 'Y':
          if (responseOptions === 'four' && isFlipped) {
            handleResponse(0);
          }
          break;
        case 'RS':
          if (isFlipped) {
            flipCard(false);
          } else {
            flipCard(true);
          }
          break;
        case 'RB':
          setPanelType(PanelType.library);
          break;
        case 'LB':
          setPanelType(PanelType.explanation);
          break;
        default:
          break;
      }
    }
  }, [lastButtonEvent]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === ' ' || event.code === 'Space') {
        event.preventDefault();
        scrollContainerRef.current?.scrollTo({ top: 0 });
      }
    };

    window.addEventListener('keydown', handleKeyDown, { passive: false });
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [scrollContainerRef]);

  useEffect(() => {
    setScrollTarget(scrollContainerRef.current);

    return () => {
      setScrollTarget(null);
    };
  }, [setScrollTarget]);

  useEffect(() => {
    setActiveFlashcardVariant(
      flashcard?.contents[flashcard.scheduling.difficultyIndex]
    );
    setReportIsOpen(false);
  }, [flashcard]);

  // Reset Report Value on Flashcard Change
  useEffect(() => {
    setReportValue('');
  }, [flashcard?.id]);

  // Close the Flashcard Menu on Flip
  useEffect(() => {
    setSpeedDialIsOpen(false);
  }, [isFlipped]);

  useEffect(() => {
    if (panelType !== PanelType.report) {
      setKeybindsDisabled(false);
    }
  }, [panelType]);

  if (!flashcard || !activeFlashcardVariant) {
    return null;
  }

  const keybinds = (key: string) => {
    if (key === 'esc') {
      setKeybindsDisabled(false);
      setReportIsOpen(false);
      setPanelType(PanelType.explanation);
    }
    if (keybindsDisabled) return;
    if (isFlipped && key === '1') {
      if (currentUser?.responseOptions === 'four') {
        handleResponse(0);
      } else {
        handleResponse(1);
      }
    }
    if (isFlipped && key === '2') {
      if (currentUser?.responseOptions === 'four') {
        handleResponse(1);
      } else {
        handleResponse(2);
      }
    }
    if (isFlipped && key === '3') {
      if (currentUser?.responseOptions === 'four') {
        handleResponse(2);
      }
    }
    if (isFlipped && key === '4') {
      if (currentUser?.responseOptions === 'four') {
        handleResponse(3);
      }
    }
    if (
      !isFlipped &&
      (key === '1' || key === '2' || key === '3' || key === '4')
    ) {
      flipCard(true);
    }
    if (key === 'r') {
      if (!isFlipped) {
        setReportIsOpen(true);
      } else {
        setPanelType(PanelType.report);
      }
    }
    if (key === 'e') {
      setPanelType(PanelType.explanation);
    }
    // if (key === 'comma' && currentUser?.role === 'admin') {
    //   setIsEditing(true);
    //   setKeybindsDisabled(true);
    // }
    if (key === 'backspace') {
      suspendCard();
    }
    if (key === 'l') {
      setPanelType(PanelType.library);
    }
  };

  const handleResponseOptionChange = (
    _event: React.MouseEvent<HTMLElement>,
    newResponseOptions: ResponseOption
  ) => {
    if (newResponseOptions !== null) {
      setResponseOptions(newResponseOptions);
      updateResponseOptions(newResponseOptions);
    }
  };

  const updateResponseOptions = async (newResponseOptions: ResponseOption) => {
    const updateUser = await updateCurrentUserInfo({
      responseOptions: newResponseOptions,
    });
    if (updateUser) {
      dispatch(setUserInfo(updateUser));
    }
  };

  const handleTextSizeChange = (
    _event: React.MouseEvent<HTMLElement>,
    newTextSize: 'smaller' | 'default' | 'larger'
  ) => {
    if (newTextSize !== null) {
      setFlashcardTextSize(newTextSize);
      updateTextSize(newTextSize);
    }
  };

  const updateTextSize = async (newSize: 'smaller' | 'default' | 'larger') => {
    const updateUser = await updateCurrentUserInfo({
      flashcardTextSize: newSize,
    });
    if (updateUser) {
      dispatch(setUserInfo(updateUser));
    }
  };

  const handleShowIntervalsChange = (showIntervals: boolean) => {
    setShowIntervals(showIntervals);
    updateShowIntervals(showIntervals);
  };

  const updateShowIntervals = async (showIntervals: boolean) => {
    const updateUser = await updateCurrentUserInfo({
      showIntervals: showIntervals,
    });
    if (updateUser) {
      dispatch(setUserInfo(updateUser));
    }
  };

  // Mobile Shortcut Clicks
  // const handleCardClick = (event: React.MouseEvent<HTMLDivElement>) => {
  //   if (speedDialIsOpen) {
  //     setSpeedDialIsOpen(false);
  //     return;
  //   }
  //   if (windowWidth > 900) return;
  //   if (isFlipped) {
  //     const { clientX, currentTarget } = event;
  //     const { offsetWidth, offsetLeft } = currentTarget;
  //     if (clientX - offsetLeft < offsetWidth / 2) {
  //       handleResponse(1);
  //     }
  //     if (clientX - offsetLeft > offsetWidth / 2) {
  //       handleResponse(2);
  //     }
  //   } else {
  //     flipCard(true);
  //   }
  //   hapticsImpactLight();
  // };

  const suspendCard = async () => {
    const response = await suspendFlashcard(flashcard.id);
    if (response) {
      dispatch(removeFromNew(flashcard.id));
      dispatch(removeFromDue(flashcard.id));
      dispatch(removeFromPast(flashcard.id));
      dispatch(incrementCompletedFlashcards());
      enqueueSnackbar('Flashcard suspended.', {
        autoHideDuration: 3000,
      });
      fetchSuspendedFlashcards();
    }
  };

  const isLinkedToday = (linkedAt: string | Date) => {
    if (typeof linkedAt === 'string') {
      linkedAt = new Date(linkedAt);
    }
    const today = new Date();
    return linkedAt.toDateString() === today.toDateString();
  };

  const renderLinkedTodayIcon = () => {
    if (flashcard.linkedAt && isLinkedToday(flashcard.linkedAt)) {
      return (
        <Tooltip
          title="Assigned due to a missed QBank question in today's session."
          enterTouchDelay={0}
        >
          <div className="linked-today">
            <Link />
          </div>
        </Tooltip>
      );
    }
  };

  return (
    <>
      <div
        className={`flashcard${flashcardTextSize === 'smaller' ? ' text-size-smaller' : ''}${flashcardTextSize === 'larger' ? ' text-size-larger' : ''}`}
      >
        <div className="flashcard_main">
          <div className="flashcard_main_card">
            <div
              className="flashcard_main_card_container"
              ref={scrollContainerRef}
              // onClick={handleCardClick}
            >
              <div className="flashcard_main_card_container_content">
                {isFlipped
                  ? parse(activeFlashcardVariant.back)
                  : parse(activeFlashcardVariant.front)}
              </div>
              {!isFlipped && reportIsOpen && (
                <div className="flashcard_main_card_container_explanation p-t-0">
                  <div className="study-panel">
                    <div className="study-panel_body">
                      <ReportCard
                        setReportIsOpen={setReportIsOpen}
                        setPanelType={setPanelType}
                        flashcard={flashcard}
                        flashcardVariant={activeFlashcardVariant}
                        reportValue={reportValue}
                        setReportValue={setReportValue}
                      />
                    </div>
                  </div>
                </div>
              )}
              {isFlipped && (
                <div className="flashcard_main_card_container_explanation">
                  <StudyPanel
                    panelType={panelType}
                    setReportIsOpen={setReportIsOpen}
                    setPanelType={setPanelType}
                    phase={StudyPhase.flashcard}
                    targetArticleId={flashcard?.topics[0]?.articleId}
                    flashcard={flashcard}
                    flashcardVariant={activeFlashcardVariant}
                    reportValue={reportValue}
                    setReportValue={setReportValue}
                  >
                    <div>
                      {activeFlashcardVariant.explanation && (
                        <div className="explanation">
                          {activeFlashcardVariant.Image &&
                            activeFlashcardVariant.Image.length > 0 && (
                              <ImageDisplay
                                images={activeFlashcardVariant.Image}
                                displayMainImage={true}
                                activeContentId={
                                  activeFlashcardVariant.flashcardId
                                }
                              />
                            )}
                          {parse(activeFlashcardVariant.explanation || '')}
                        </div>
                      )}
                      {Array.isArray(activeFlashcardVariant?.references) &&
                        activeFlashcardVariant?.references.length > 0 &&
                        activeFlashcardVariant?.references[0] && (
                          <div className="references">
                            <ul
                              className={
                                activeFlashcardVariant?.references.length === 1
                                  ? 'one-ref'
                                  : ''
                              }
                            >
                              {activeFlashcardVariant.references.map(
                                (reference, index) => (
                                  <li key={index}>{parse(reference)}</li>
                                )
                              )}
                            </ul>
                          </div>
                        )}
                    </div>
                  </StudyPanel>
                </div>
              )}
              {currentUser?.role === 'admin' && (
                <div className="admin-toolbar">
                  <div className="qa-status">
                    {flashcard.isQAed ? (
                      <div className="is-qaed">Is QAed</div>
                    ) : (
                      <div className="is-not-qaed">Is Not QAed</div>
                    )}
                  </div>
                  <div className="spacer">|</div>
                  <button
                    className="uuid-display"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      copyToClipboard(activeFlashcardVariant.flashcardId);
                    }}
                  >
                    <Copy />
                    {activeFlashcardVariant?.flashcardId}
                  </button>
                </div>
              )}
              {renderLinkedTodayIcon()}
            </div>
            <FlashcardMenu
              speedDialIsOpen={speedDialIsOpen}
              setSpeedDialIsOpen={setSpeedDialIsOpen}
              undoResponse={undoResponse}
              setIsSelectGamepadModalOpen={setIsSelectGamepadModalOpen}
              setReportIsOpen={setReportIsOpen}
              setPanelType={setPanelType}
              isFlipped={isFlipped}
              suspendedCardCount={suspendedCardCount}
              setShowSuspendedCards={setShowSuspendedCards}
              setFlashcardSettingsIsOpen={setFlashcardSettingsIsOpen}
              gamepadType={gamepadType}
              suspendCard={suspendCard}
              prevFlashcard={prevFlashcard}
            />
            {flashcard.yield > 0 && !isDiagnostic && (
              <Tooltip
                title={windowWidth > 900 ? 'Flashcard Yield' : ''}
                enterTouchDelay={0}
              >
                <div className="yield-score">
                  <span className="yield-score_score">
                    {flashcard.yield.toFixed(0)}
                  </span>
                  <CircularProgress
                    variant="determinate"
                    color="success"
                    value={100}
                    sx={{ height: '2rem !important', width: '2rem !important' }}
                  />
                </div>
              </Tooltip>
            )}
          </div>
        </div>
        <div className={`flashcard_actions ${currentUser?.responseOptions}`}>
          {!isFlipped && (
            <>
              <div className="rating-wrapper">
                <Tooltip
                  title={windowWidth > 900 ? 'Shortcut: Spacebar' : ''}
                  placement="top"
                  enterDelay={500}
                  disableTouchListener
                  arrow
                  enterTouchDelay={0}
                >
                  <button
                    className="button button--reveal button--glass"
                    onClick={() => {
                      flipCard(true);
                      hapticsImpactLight();
                    }}
                  >
                    Reveal
                    {gamepadType && (
                      <GamepadButtonIcon
                        controllerType={gamepadType}
                        universalButton={'A'}
                      />
                    )}
                  </button>
                </Tooltip>
              </div>
            </>
          )}
          {isFlipped && (
            <>
              {currentUser?.responseOptions === 'four' && (
                <div className="rating-wrapper">
                  <Tooltip
                    title={windowWidth > 900 ? 'Shortcut: 1' : ''}
                    placement="top"
                    enterDelay={500}
                    disableTouchListener
                    arrow
                    enterTouchDelay={0}
                  >
                    <button
                      className="button button--rating button--rating--again button--glass"
                      onClick={() => {
                        handleResponse(0);
                        hapticsImpactLight();
                      }}
                    >
                      Again
                      {gamepadType && (
                        <GamepadButtonIcon
                          controllerType={gamepadType}
                          universalButton={'Y'}
                        />
                      )}
                    </button>
                  </Tooltip>
                  {showIntervals && (
                    <div className="interval">{newScheduling[0]}</div>
                  )}
                </div>
              )}
              <div className="rating-wrapper">
                <Tooltip
                  title={
                    windowWidth > 900
                      ? 'Shortcut: ' +
                        (currentUser?.responseOptions === 'four' ? '2' : '1')
                      : ''
                  }
                  placement="top"
                  enterDelay={500}
                  disableTouchListener
                  arrow
                  enterTouchDelay={0}
                >
                  <button
                    className="button button--rating button--rating--wrong button--glass"
                    onClick={() => {
                      handleResponse(1);
                      hapticsImpactLight();
                    }}
                  >
                    Wrong
                    {gamepadType && (
                      <GamepadButtonIcon
                        controllerType={gamepadType}
                        universalButton={'X'}
                      />
                    )}
                  </button>
                </Tooltip>
                {showIntervals && (
                  <div className="interval">{newScheduling[1]}</div>
                )}
              </div>
              <div className="rating-wrapper">
                <Tooltip
                  title={
                    windowWidth > 900
                      ? 'Shortcut: ' +
                        (currentUser?.responseOptions === 'four'
                          ? '3 or Spacebar'
                          : '2 or Spacebar')
                      : ''
                  }
                  placement="top"
                  enterDelay={500}
                  disableTouchListener
                  arrow
                  enterTouchDelay={0}
                >
                  <button
                    className="button button--rating button--rating--right button--glass"
                    onClick={() => {
                      handleResponse(2);
                      hapticsImpactLight();
                    }}
                  >
                    Right
                    {gamepadType && (
                      <GamepadButtonIcon
                        controllerType={gamepadType}
                        universalButton={'A'}
                      />
                    )}
                  </button>
                </Tooltip>
                {showIntervals && (
                  <div className="interval">{newScheduling[2]}</div>
                )}
              </div>
              {currentUser?.responseOptions === 'four' && (
                <div className="rating-wrapper">
                  <Tooltip
                    title={windowWidth > 900 ? 'Shortcut: 4' : ''}
                    placement="top"
                    enterDelay={500}
                    disableTouchListener
                    arrow
                    enterTouchDelay={0}
                  >
                    <button
                      className="button button--rating button--rating--easy button--glass"
                      onClick={() => {
                        handleResponse(3);
                        hapticsImpactLight();
                      }}
                    >
                      Easy
                      {gamepadType && (
                        <GamepadButtonIcon
                          controllerType={gamepadType}
                          universalButton={'B'}
                        />
                      )}
                    </button>
                  </Tooltip>
                  {showIntervals && (
                    <div className="interval">{newScheduling[3]}</div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
        <CustomModal
          open={flashcardSettingsIsOpen}
          onClose={() => setFlashcardSettingsIsOpen(false)}
          name={'flashcard-preferences'}
          glass={true}
        >
          <div className="modal_header">
            <h2>Flashcard Preferences</h2>
          </div>
          <div className="modal_content">
            <div className="control control--intervals">
              <InputLabel>Show Intervals</InputLabel>
              <OraSwitch
                checked={showIntervals}
                onChange={(e) => handleShowIntervalsChange(e.target.checked)}
              />
            </div>
            <div className="control control--response-options">
              <label style={{ display: 'block' }} className="m-b-0-50">
                <strong>Response Options</strong>
              </label>
              <ToggleButtonGroup
                value={responseOptions}
                exclusive
                onChange={handleResponseOptionChange}
                aria-label="response options"
              >
                <ToggleButton value="two" aria-label="2 response">
                  Two Buttons
                </ToggleButton>
                <ToggleButton value="four" aria-label="4 response">
                  Four Buttons
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
            <div className="control control--text-size m-t-1">
              <label style={{ display: 'block' }} className="m-b-0-50">
                <strong>Text Size</strong>
              </label>
              <ToggleButtonGroup
                value={flashcardTextSize}
                exclusive
                onChange={handleTextSizeChange}
                aria-label="Text size options"
              >
                <ToggleButton value="smaller" aria-label="Smaller text size">
                  Smaller
                </ToggleButton>
                <ToggleButton value="default" aria-label="Default text size">
                  Default
                </ToggleButton>
                <ToggleButton value="larger" aria-label="Larger text size">
                  Larger
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>
        </CustomModal>
      </div>
      {/* {currentUser?.role === 'admin' && (
        <EditFlashcard
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          flashcardId={flashcard.id}
          activeFlashcard={flashcard}
          activeFlashcardVariant={activeFlashcardVariant}
          setKeybindsDisabled={setKeybindsDisabled}
        />
      )} */}
    </>
  );
};

export default Flashcard;
