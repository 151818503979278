export const getOraCookieAcceptCookie = () => {
  const cookies = document.cookie.split('; ');
  const cookie = cookies.find((row) => row.startsWith('ora-accepted-cookies='));
  return cookie ? cookie.split('=')[1] : null;
};

export const setOraCookieAcceptCookie = (choice: string) => {
  const expiryDate = new Date();
  expiryDate.setDate(expiryDate.getDate() + 30);
  const environment = process.env.NODE_ENV;
  if (environment === 'production') {
    document.cookie = `ora-accepted-cookies=${choice}; expires=${expiryDate.toUTCString()}; Domain=.oraai.com; Path=/; SameSite=Lax; Secure`;
  } else {
    document.cookie = `ora-accepted-cookies=${choice}; expires=${expiryDate.toUTCString()}; Path=/; SameSite=Lax`;
  }
};
