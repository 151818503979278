import Tooltip from '@mui/material/Tooltip';
import { Flag, MoreVertical, RotateCcw, Slash, Sliders } from 'react-feather';
import { Intercom } from '../../../../assets/svgs/Intercom';
import KeybindTooltip from '../../../Misc/KeybindTooltip';
import { renderHint } from '../../../../utils/renderHint';
import { SuspendedCards } from '../../../../assets/svgs/SuspendedCards';
import { GameController } from '@phosphor-icons/react';
import useWindowWidth from '../../../../hooks/useWindowWidth';
import { hapticsImpactLight } from '../../../../utils/haptics';
import { PanelType } from '../../../../types/Study';
import { Menu } from '@mui/material';
import { useRef } from 'react';

interface FlashcardMenuProps {
  speedDialIsOpen: boolean;
  setSpeedDialIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  undoResponse: () => void;
  setIsSelectGamepadModalOpen: (isOpen: boolean) => void;
  setReportIsOpen: (isOpen: boolean) => void;
  setPanelType: (type: PanelType) => void;
  isFlipped: boolean;
  suspendedCardCount: number;
  setShowSuspendedCards: (show: boolean) => void;
  setFlashcardSettingsIsOpen: (isOpen: boolean) => void;
  gamepadType: string | null;
  suspendCard: () => void;
  prevFlashcard: boolean;
}

const FlashcardMenu: React.FC<FlashcardMenuProps> = ({
  speedDialIsOpen,
  setSpeedDialIsOpen,
  undoResponse,
  setIsSelectGamepadModalOpen,
  setReportIsOpen,
  setPanelType,
  isFlipped,
  suspendedCardCount,
  setShowSuspendedCards,
  setFlashcardSettingsIsOpen,
  gamepadType,
  suspendCard,
  prevFlashcard,
}) => {
  const windowWidth = useWindowWidth();
  const speedDialRef = useRef<HTMLButtonElement>(null);
  return (
    <div className="flashcard-menu">
      <button
        ref={speedDialRef}
        className="button button--flashcard-menu"
        onClick={() => {
          hapticsImpactLight();
          setSpeedDialIsOpen((prevOpen) => !prevOpen);
        }}
      >
        <MoreVertical />
      </button>
      <Menu
        open={speedDialIsOpen}
        onClose={() => setSpeedDialIsOpen(false)}
        anchorEl={speedDialRef.current}
        className="flashcard-menu"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {prevFlashcard && (
          <button
            className="button flashcard-menu-item with-key"
            onClick={() => {
              hapticsImpactLight();
              undoResponse();
              setSpeedDialIsOpen(false);
              renderHint('undo', 'Next time, press Z to undo your response.');
            }}
          >
            <RotateCcw />
            <span>Undo</span>
            <div className="hint">
              <span>Z</span>
            </div>
          </button>
        )}
        <button
          className="button flashcard-menu-item with-key button--report"
          onClick={() => {
            if (!isFlipped) {
              setReportIsOpen(true);
            } else {
              setPanelType(PanelType.report);
            }
            setSpeedDialIsOpen(false);
          }}
        >
          <Flag />
          <span>Report</span>

          <div className="hint">
            <span>R</span>
          </div>
        </button>
        <Tooltip
          title={
            windowWidth > 900 ? (
              <KeybindTooltip
                name={'Suspend Card'}
                keyLetter={'delete'}
                description={
                  'Suspend this card. Suspended cards will be hidden from future sessions.'
                }
              />
            ) : (
              ''
            )
          }
          placement="right"
          enterTouchDelay={0}
        >
          <button
            className="button flashcard-menu-item with-key button--report"
            onClick={() => {
              hapticsImpactLight();
              suspendCard();
            }}
          >
            <Slash />
            <span>Suspend Card</span>
            <div className="hint long-hint">
              <span>delete</span>
            </div>
          </button>
        </Tooltip>
        {suspendedCardCount > 0 && (
          <button
            className="button flashcard-menu-item"
            onClick={() => {
              hapticsImpactLight();
              setShowSuspendedCards(true);
              setSpeedDialIsOpen(false);
            }}
          >
            <SuspendedCards />
            <span>Suspended Cards</span>
          </button>
        )}
        <button
          className="button flashcard-menu-item"
          onClick={() => {
            hapticsImpactLight();
            setFlashcardSettingsIsOpen(true);
            setSpeedDialIsOpen(false);
          }}
        >
          <Sliders />
          <span>Preferences</span>
        </button>
        <button
          className="button flashcard-menu-item button--help"
          onClick={() => {
            window.Intercom('showNewMessage');
            setSpeedDialIsOpen(false);
            hapticsImpactLight();
          }}
        >
          <Intercom />
          <span>Help</span>
        </button>
        {gamepadType && (
          <Tooltip
            title={
              windowWidth > 900 ? (
                <KeybindTooltip name={'Change Controller'} />
              ) : (
                ''
              )
            }
            enterTouchDelay={0}
          >
            <button
              className="button flashcard-menu-item button--controller"
              onClick={() => {
                hapticsImpactLight();
                setIsSelectGamepadModalOpen(true);
              }}
            >
              <GameController />
              <span>Controller</span>
            </button>
          </Tooltip>
        )}
      </Menu>
    </div>
  );
};

export default FlashcardMenu;
