export const secondsToDisplayTime = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  if (seconds < 60) {
    return '<1min';
  }
  if (hours > 0) {
    return `${hours}<span class="time-labels">h</span> ${minutes.toString()}<span class="time-labels">min</span>`;
  } else {
    return `${minutes.toString()}<span class="time-labels">min</span>`;
  }
};
