export const OraMuiMenuItem = {
  styleOverrides: {
    root: {
      fontFamily: 'var(--font-family-heading)',
      fontWeight: 500,
      fontSize: '0.875rem',
      backgroundColor: 'transparent !important',
      display: 'flex',
      gap: '0.25rem',
      alignItems: 'center',
      padding: '0.375rem 1.5rem 0.375rem 0.75rem !important',
      svg: {
        opacity: 0,
      },
      '&.Mui-selected': {
        svg: {
          opacity: 1,
        },
      },
      '&:hover:not(.Mui-selected)': {
        backgroundColor: 'transparent !important',
        svg: {
          opacity: 0.5,
        },
      },
      '&:first-child': {
        paddingTop: '0.75rem !important',
        paddingBottom: '0.375rem !important',
      },
      '&:last-child': {
        paddingTop: '0.375rem !important',
        paddingBottom: '0.75rem !important',
      },
      '&:hover': {
        backgroundColor: 'transparent !important',
      },
    },
  },
};
