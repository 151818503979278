export const OraMuiTextField = {
  styleOverrides: {
    root: {
      color: 'var(--type-body-inverse)',
      backgroundColor: 'var(--surface-glass)',
      borderRadius: '0.5rem',
      '& .MuiFormLabel-root': {
        color: 'var(--type-body-inverse) !important',
        fontFamily: 'var(--font-family-base)',
        fontWeight: 400,
      },
      '& .MuiOutlinedInput-root': {
        color: 'var(--type-body-inverse)',
        borderRadius: '0.5rem',
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'transparent', // default state
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: 'var(--type-body-inverse)', // hover state
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: 'var(--type-body-inverse)', // focused state
        },
      },
    },
  },
};
