import Tooltip from '@mui/material/Tooltip';
import { hapticsImpactLight } from '../../../utils/haptics';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { useEffect, useState } from 'react';
import { updateStudySession } from '../../../services/studySession';
import { posthog } from 'posthog-js';
import { setTodaySession } from '../../../slices/todaySession/todaySessionSlice';
import useWindowWidth from '../../../hooks/useWindowWidth';
import KeybindTooltip from '../../Misc/KeybindTooltip';
import { Clock } from 'react-feather';

interface SnoozeSessionProps {}

const SnoozeSession: React.FC<SnoozeSessionProps> = () => {
  const todaySession = useSelector((state: RootState) => state.todaySession);
  const [hasClickedSnoozed, setHasClickedSnoozed] = useState(false);

  const dispatch = useDispatch();
  const windowWidth = useWindowWidth();

  useEffect(() => {
    const clickedSnooze = localStorage.getItem('ora-clickedSnooze');
    if (clickedSnooze === 'true') {
      setHasClickedSnoozed(true);
    }
  }, []);

  const handleSnoozeSession = async () => {
    const updatedSession = { ...todaySession, isSnoozed: true };
    const response = await updateStudySession(todaySession.id, updatedSession);
    posthog?.capture('study_session_snooze');
    dispatch(setTodaySession(response));
  };

  return (
    <Tooltip
      title={
        windowWidth > 1000 ? (
          <KeybindTooltip
            name={'Snooze Session'}
            description={
              'Everyone deserves a day off! Your workload will be automatically rebalanced.'
            }
          />
        ) : (
          ''
        )
      }
      enterTouchDelay={0}
    >
      <button
        className={`button button--snooze ${hasClickedSnoozed ? 'truncated' : ''}`}
        onClick={() => {
          handleSnoozeSession();
          hapticsImpactLight();
          localStorage.setItem('ora-clickedSnooze', 'true');
        }}
      >
        <Clock />
        <span>Snooze Session</span>
      </button>
    </Tooltip>
  );
};

export default SnoozeSession;
