import React from 'react';
import { ArrowLeft, ArrowRight } from 'react-feather';
import { hapticsImpactLight } from '../../utils/haptics';
import { TriangleRight } from '../../assets/svgs/TriangleRight';
import { TriangleDown } from '../../assets/svgs/TriangleDown';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Page from '../../components/Page';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';
import MSHeader from '../../components/NonAuth/MSHeader';
import MSFooter from '../../components/NonAuth/MSFooter';
import NonAuthPage from '../../components/NonAuth/NonAuthPage';
import { useNavigate } from 'react-router-dom';

const Roadmap: React.FC = () => {
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);

  const navigate = useNavigate();

  const renderRoadmap = () => {
    return (
      <div className="roadmap">
        <button
          className="button button--link button--back"
          onClick={() => {
            hapticsImpactLight();
            navigate('/help');
          }}
        >
          <ArrowLeft /> Help Center
        </button>
        <h1 className="m-t-0">Feature Roadmap</h1>
        <p>Take a look at the future of Ora.</p>
        <div className="table-wrapper">
          <div className="table-column">
            <div className="table-column_header">Upcoming</div>
            <div className="table-column_body">
              <button
                className="ticket ticket--feedback"
                onClick={() => {
                  window.Intercom('showNewMessage');
                  hapticsImpactLight();
                }}
              >
                <div className="ticket_title">Your Feature Here</div>
                <div className="ticket_description">
                  Tell us what you want to see in Ora.
                </div>
              </button>
              <div className="ticket">
                <div className="ticket_title">7-Block Practice Tests</div>
                <div className="ticket_description">
                  Simulate the real exam experience.
                </div>
              </div>
              <div className="ticket">
                <div className="ticket_title">Lecture Videos</div>
                <div className="ticket_description">
                  Learn topics from comprehensive lecture videos.
                </div>
              </div>
              <div className="ticket">
                <div className="ticket_title">Answer Choice Frequency</div>
                <div className="ticket_description">
                  See how often each answer choice is selected by Ora users.
                </div>
              </div>
              <div className="ticket">
                <div className="ticket_title">Assign Flashcards by Article</div>
                <div className="ticket_description">
                  Add flashcards to your session from library articles.
                </div>
              </div>
              <div className="ticket">
                <div className="ticket_title">Anki Sync</div>
                <div className="ticket_description">
                  Transfer learning progress from Anki to Ora.
                </div>
              </div>
              <div className="ticket">
                <div className="ticket_title">NBME Import</div>
                <div className="ticket_description">
                  Import your NBME scores to further personalize your study
                  plan.
                </div>
              </div>
            </div>
          </div>
          <div className="table-column">
            <div className="table-column_header">In Progress</div>
            <div className="table-column_body">
              <div className="ticket">
                <div className="ticket_title">Personalized Time Estimates</div>
                <div className="ticket_description">
                  Tailor the estimated study time to your learning habits.
                </div>
              </div>
              <div className="ticket">
                <div className="ticket_title">Improved Session Summary</div>
                <div className="ticket_description">
                  A more rewarding and informative session summary.
                </div>
              </div>
            </div>
          </div>
          <div className="table-column">
            <div className="table-column_header">Done</div>
            <div className="table-column_body table-column_body--done">
              <div className="ticket">
                <div className="ticket_title">QBank Image Display</div>
                <div className="ticket_description">
                  A new view for QBank questions that displays related images.
                </div>
              </div>
              <div className="ticket">
                <div className="ticket_title">Autopilot V2</div>
                <div className="ticket_description">
                  The next generation of Ora's scheduling algorithm.
                </div>
              </div>
              <div className="ticket">
                <div className="ticket_title">Controller Support</div>
                <div className="ticket_description">
                  Use game controllers to study on Ora.
                </div>
              </div>
              <div className="ticket">
                <div className="ticket_title">Timezone Support</div>
                <div className="ticket_description">
                  Specify the timezone for your daily session rollover.
                </div>
              </div>
              <div className="ticket">
                <div className="ticket_title">Study Reminders</div>
                <div className="ticket_description">
                  Get notified when it's time to study.
                </div>
              </div>
              <div className="ticket">
                <div className="ticket_title">Study Ahead</div>
                <div className="ticket_description">
                  Study beyond your daily assignment.
                </div>
              </div>
              <div className="ticket">
                <div className="ticket_title">Modify Daily Session</div>
                <div className="ticket_description">
                  Change your daily session content quantity.
                </div>
              </div>
              <div className="ticket">
                <div className="ticket_title">Keywords</div>
                <div className="ticket_description">
                  Quick definitions of key terms on hover.
                </div>
              </div>
              <Accordion>
                <AccordionSummary
                  aria-controls="more-roadmap-content"
                  id="more-roadmap-header"
                  sx={{ padding: 0 }}
                >
                  <div className="row">
                    <div className="icon">
                      <TriangleRight classes="expand-icon expand-icon--closed" />
                      <TriangleDown classes="expand-icon expand-icon--open" />
                    </div>
                    <span className="button button--link--glass p-l-0">
                      Show More
                    </span>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="table-column-accordion">
                    <div className="ticket">
                      <div className="ticket_title">Android App</div>
                      <div className="ticket_description">
                        Use Ora on your Android devices.
                      </div>
                    </div>
                    <div className="ticket">
                      <div className="ticket_title">Suspend Flashcard</div>
                      <div className="ticket_description">
                        Remove a flashcard from your due content.
                      </div>
                    </div>
                    <div className="ticket">
                      <div className="ticket_title">QBank Variants</div>
                      <div className="ticket_description">
                        Study different versions of QBank questions that test
                        the same topic.
                      </div>
                    </div>

                    <div className="ticket">
                      <div className="ticket_title">Content Library</div>
                      <div className="ticket_description">
                        View all your study material in one place.
                      </div>
                    </div>
                    <div className="ticket">
                      <div className="ticket_title">Snooze Daily Session</div>
                      <div className="ticket_description">
                        Snooze your session until tomorrow. Keep your streak.
                      </div>
                    </div>
                    <div className="ticket">
                      <div className="ticket_title">Topic Customization</div>
                      <div className="ticket_description">
                        Filter exams to study specific topics.
                      </div>
                    </div>
                    <div className="ticket">
                      <div className="ticket_title">
                        Improved Performance Dashboard
                      </div>
                      <div className="ticket_description">
                        A better look into your progress and performance.
                      </div>
                    </div>
                    <div className="ticket">
                      <div className="ticket_title">
                        Scheduling Algorithm Improvements
                      </div>
                      <div className="ticket_description">
                        More accurate scheduling for your study sessions.
                      </div>
                    </div>
                    <div className="ticket">
                      <div className="ticket_title">Flashcard Linking</div>
                      <div className="ticket_description">
                        Automatically assign flashcards related to QBank
                        questions you miss.
                      </div>
                    </div>
                    <div className="ticket">
                      <div className="ticket_title">Load Balancer V1</div>
                      <div className="ticket_description">
                        Automatically redistribute your study load if you miss a
                        day.
                      </div>
                    </div>
                    <div className="ticket">
                      <div className="ticket_title">iOS App</div>
                      <div className="ticket_description">
                        Releasing on July 22nd, 2024.
                      </div>
                    </div>
                    <div className="ticket">
                      <div className="ticket_title">Untimed Mode</div>
                      <div className="ticket_description">
                        Alternative to Timed mode for QBank.
                      </div>
                    </div>
                    {/* <div className="ticket">
                    <div className="ticket_title">Onboarding Assessment</div>
                    <div className="ticket_description">
                      Jumpstart using Ora with a preliminary exam to test your
                      strengths and weaknesses.
                    </div>
                  </div> */}
                    <div className="ticket">
                      <div className="ticket_title">Ora Beta Launch</div>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
        <div className="roadmap_footer">
          <span>Want to voice your opinion on our roadmap?</span>
          <button
            className="button button--link--glass"
            onClick={() => {
              window.Intercom('showNewMessage');
              hapticsImpactLight();
            }}
          >
            Share Your Thoughts <ArrowRight />
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
      {currentUser && <Page>{renderRoadmap()}</Page>}
      {!currentUser && (
        <>
          <MSHeader />
          <NonAuthPage>{renderRoadmap()}</NonAuthPage>
          <MSFooter />
        </>
      )}
    </>
  );
};

export default Roadmap;
