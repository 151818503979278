import React, { useState, useEffect, ReactNode } from 'react';
import Popover from '@mui/material/Popover';

interface PopoverData {
  src: string;
  alt: string;
  caption: string;
  credit: string;
}

interface ImagePopoverProps {
  parsedExplanation: ReactNode[];
}

const ImagePopover: React.FC<ImagePopoverProps> = ({ parsedExplanation }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [popoverData, setPopoverData] = useState<PopoverData | null>(null);

  useEffect(() => {
    const spans = document.querySelectorAll('span[data-image-src-explanation]');

    const handleMouseEnter: EventListener = (event: Event) => {
      const target = event.currentTarget as HTMLElement;
      setAnchorEl(target);

      setPopoverData({
        src: target.getAttribute('data-image-src-explanation') || '',
        alt: target.getAttribute('alt') || '',
        caption: target.getAttribute('data-image-caption') || '',
        credit: target.getAttribute('data-image-credit') || '',
      });
    };

    const handleMouseLeave: EventListener = () => {
      setAnchorEl(null);
    };

    if (!spans || spans.length === 0) return;

    spans.forEach((span) => {
      span.addEventListener('mouseenter', handleMouseEnter);
      span.addEventListener('mouseleave', handleMouseLeave);
    });

    return () => {
      if (!spans || spans.length === 0) return;
      spans.forEach((span) => {
        span.removeEventListener('mouseenter', handleMouseEnter);
        span.removeEventListener('mouseleave', handleMouseLeave);
      });
    };
  }, [parsedExplanation]);

  return (
    <>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
          padding: '1rem',
        }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={() => {
          setAnchorEl(null);
          setPopoverData(null);
        }}
        disableRestoreFocus
      >
        <div>
          {popoverData && (
            <>
              <img
                src={popoverData.src}
                alt={popoverData.alt}
                style={{ width: '400px', height: 'auto', maxWidth: '100%' }}
              />
              <div className="caption-credit">
                <div className="caption">{popoverData.caption}</div>
                <div className="credit">{popoverData.credit}</div>
              </div>
            </>
          )}
        </div>
      </Popover>
    </>
  );
};

export default ImagePopover;
