export const OraMuiAutocomplete = {
  styleOverrides: {
    root: {
      '& .MuiFormControl-root label': {
        display: 'none',
      },
      // Target the outlined input inside the autocomplete
      '& .MuiOutlinedInput-root': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'var(--surface-glass-light)', // default state
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          backgroundColor: 'var(--surface-glass-light-focus)',
          borderColor: 'transparent !important', // hover state
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          backgroundColor: 'var(--surface-glass-light)',
          borderColor: 'transparent !important', // focused state
        },
      },
    },
  },
};
