/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useCallback } from 'react';
import parse from 'html-react-parser';
import {
  Article,
  ArticleImage,
  ArticleReference,
  ArticleSection,
} from '../../../types/Article';
import {
  AlertTriangle,
  Bookmark as BookmarkIcon,
  Check,
  ChevronDown,
  Copy,
  Edit3,
  ExternalLink,
  Search,
  Share,
  ToggleLeft,
  ToggleRight,
  X,
} from 'react-feather';
import ReportArticle from '../ReportArticle';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { RootState } from '../../../store/store';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { hapticsImpactLight } from '../../../utils/haptics';
import {
  fetchArticle,
  fetchArticleVignettes,
  updateArticlePreviewBE,
  updateArticleSection,
  updateSectionQAStatus,
} from '../../../services/library';
import { enqueueSnackbar } from 'notistack';
import JumpToMenu from '../JumpToMenu';
import { Vignette } from '../../../types/Vignette';
import { copyToClipboard } from '../../../utils/copyToClipboard';
import EditVignette from '../../Study/Exam/EditVignette';
import { Flashcard, FlashcardContent } from '../../../types/Flashcard';
import EditFlashcard from '../../Study/EditFlashcard';
import {
  setVignetteQAStatus,
  updateVignetteSuspendStatus,
} from '../../../services/vignettes';
import {
  setFlashcardQAStatus,
  updateFlashcardSuspendStatus,
} from '../../../services/flashcards';
import { addBookmark } from '../../../services/bookmarks';
import { Bookmark } from '../../../types/Bookmark';
import { updateTopic } from '../../../services/topics';
import { fetchExams } from '../../../services/exams';
import { UserExam } from '../../../types/User';
import CustomModal from '../../Global/CustomModal';
import { Helmet } from 'react-helmet';
import ArticlePreview from '../ArticlePreview';
import { renderLoadingFrames } from '../../../utils/renderLoadingFrames';
import { ImageType } from '../../../types/Image';

interface ArticlePageProps {
  articleId: string;
  isStudyPanel: boolean;
  bookmarks: Bookmark[];
  setBookmarks: (bookmarks: Bookmark[]) => void;
  removeBookmark: (articleId: string) => void;
  setMobileMenuIsOpen: (isOpen: boolean) => void;
}

const ArticlePage: React.FC<ArticlePageProps> = ({
  articleId,
  isStudyPanel,
  bookmarks,
  setBookmarks,
  removeBookmark,
  setMobileMenuIsOpen,
}) => {
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);

  const [activeArticle, setActiveArticle] = useState<Article>();
  const [articleLoading, setArticleLoading] = useState(false);

  const [reportArticleModalIsOpen, setReportArticleModalIsOpen] =
    useState(false);
  const [headings, setHeadings] = useState<{ title: string; id: string }[]>([]);
  const [jumpLoading, setJumpLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingArticlePreview, setIsEditingArticlePreview] = useState(false);
  const [linkedVignettes, setLinkedVignettes] = useState<Vignette[]>([]);
  const [availableExams, setAvailableExams] = useState<UserExam[] | []>([]);
  const [articleImages, setArticleImages] = useState<ImageType[]>([]);
  const [isEditingVignette, setIsEditingVignette] = useState(false);
  const [activeVignette, setActiveVignette] = useState<Vignette>();
  const [isEditingFlashcard, setIsEditingFlashcard] = useState(false);
  const [activeFlashcard, setActiveFlashcard] = useState<Flashcard>();
  const [activeFlashcardVariant, setActiveFlashcardVariant] =
    useState<FlashcardContent>();

  const [currentArticleSectionId, setCurrentArticleSectionId] =
    useState<string>('');
  const [currentArticleSectionText, setCurrentArticleSectionText] =
    useState<string>('');
  const [currentArticlePreviewText, setCurrentArticlePreviewText] =
    useState<string>('');
  const [currentTopicYield, setCurrentTopicYield] = useState<number>(0);

  // Load Article by ID
  useEffect(() => {
    if (articleId) {
      loadArticleById(articleId);
    }
  }, [articleId]);

  // Update vignettes after editting
  useEffect(() => {
    if (
      !isEditingVignette &&
      !isEditingFlashcard &&
      articleId &&
      currentUser?.role === 'admin'
    ) {
      getLinkedVignettes();
      getExams();
    }
  }, [isEditingVignette, isEditingFlashcard, articleId, currentUser]);

  // Load the Jump Menu
  useEffect(() => {
    if (activeArticle && !isStudyPanel) {
      setJumpLoading(true);
      const h2Elements = Array.from(
        document.querySelectorAll(
          '.article_body_center_card_content h2:not(.article-preview h2)'
        )
      );
      if (!h2Elements || h2Elements.length === 0) return;
      const newHeadings = h2Elements.map((el) => ({
        title: el.textContent || '',
        id: el.id,
      }));
      setHeadings(newHeadings);
      setJumpLoading(false);
    }
  }, [activeArticle, isStudyPanel]);

  // Load the Article Content for Editing
  useEffect(() => {
    if (isEditingArticlePreview && activeArticle) {
      setCurrentArticlePreviewText(activeArticle.articlePreview || '');
      setCurrentTopicYield(activeArticle.topics[0].yield || 0);
    }
  }, [isEditingArticlePreview, activeArticle]);

  const loadArticleById = async (id: string) => {
    setArticleLoading(true);
    const article = await fetchArticle(id);
    if (article) {
      setActiveArticle(article);
      setArticleImages(compileArticleImages(article));
    } else {
      alert('Article not found');
    }
    setArticleLoading(false);
    window.scrollTo(0, 0);
  };

  const scrollToSection = (id: string) => {
    const targetElement = document.getElementById(id);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleArticleSectionTextChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setCurrentArticleSectionText(event.target.value);
  };

  const handleArticlePreviewTextChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setCurrentArticlePreviewText(event.target.value);
  };

  const handleTopicYieldChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCurrentTopicYield(parseInt(event.target.value));
  };

  const submitArticleSectionUpdate = async () => {
    const updatedSection = {
      text: currentArticleSectionText,
      updatedAt: moment().toISOString(),
    };
    await updateArticleSection(currentArticleSectionId, updatedSection);
    enqueueSnackbar('Article section updated successfully.', {
      autoHideDuration: 3000,
    });
    setIsEditing(false);
    if (loadArticleById && activeArticle) {
      loadArticleById(activeArticle.id);
    }
  };

  const updateArticlePreview = async () => {
    if (!activeArticle) return;
    const updatedArticle = {
      articlePreview: currentArticlePreviewText,
    };
    await updateArticlePreviewBE(activeArticle.id, updatedArticle);
  };

  const updateTopicYield = async () => {
    if (!activeArticle || !activeArticle.topics[0]) return;
    const articleID = activeArticle.id;
    const updatedTopicData = {
      yieldNumber: currentTopicYield,
    };
    await updateTopic(articleID, updatedTopicData);
  };

  const submitArticleUpdate = async () => {
    try {
      await Promise.all([updateArticlePreview(), updateTopicYield()]);
      enqueueSnackbar('Article updated successfully.', {
        autoHideDuration: 3000,
      });
      setIsEditingArticlePreview(false);
      if (loadArticleById && activeArticle) {
        loadArticleById(activeArticle.id);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const renderArticleSections = (sections: ArticleSection[]) => {
    const sortedSections = [...sections].sort((a, b) => a.ord - b.ord);
    return (
      <>
        {sortedSections.map((section, sectionIndex) => {
          const updatedText = parse(section.text, {
            replace: (domNode: any) => {
              if (
                'type' in domNode &&
                domNode.type === 'tag' &&
                'name' in domNode &&
                domNode.name === 'h2'
              ) {
                const element = domNode as {
                  attribs: { [key: string]: string };
                };
                element.attribs.id = section.id;
              } else if (domNode.attribs && domNode.attribs.href) {
                return (
                  <Link
                    to={domNode.attribs.href}
                    key={
                      domNode.attribs.href +
                      '-' +
                      sectionIndex +
                      '-' +
                      Math.floor(Math.random() * 10000) +
                      1
                    }
                    className="internal-link"
                  >
                    {domNode.children[0]?.data}
                  </Link>
                );
              }
            },
          });

          return (
            <section
              id={'spy-' + sectionIndex}
              className="article-section"
              key={sectionIndex}
            >
              {currentUser?.role === 'admin' && !currentUser?.demoMode && (
                <div className="admin-toolbar">
                  <div className="qa-status">
                    {section.isQAed ? (
                      <div className="is-qaed">Is QAed</div>
                    ) : (
                      <div className="is-not-qaed">Is Not QAed</div>
                    )}
                  </div>
                  <button
                    className="button button--edit"
                    style={{
                      marginLeft: '0.25rem',
                      height: '1.5rem',
                      width: '1.5rem',
                      padding: '0',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    onClick={() => {
                      toggleSectionQAStatus(section.id, !section.isQAed);
                    }}
                  >
                    {section.isQAed ? <X /> : <Check />}
                  </button>
                  <div className="spacer">|</div>
                  <button
                    className="button button--secondary button--edit"
                    onClick={() => {
                      setIsEditing(true);
                      setCurrentArticleSectionText(section.text);
                      setCurrentArticleSectionId(section.id);
                    }}
                  >
                    <Edit3 />
                    Edit
                  </button>
                </div>
              )}
              {updatedText}
              {renderArticleSectionImages(section.images)}
              <div className="reference-container">
                {renderArticleSectionReferences(section.references)}
              </div>
            </section>
          );
        })}
      </>
    );
  };

  const renderArticleSectionImages = useCallback((images: ArticleImage[]) => {
    if (!images || !images.length) return null;
    return (
      <div className="article-section-images">
        {images.map((image, i) => {
          return (
            <div key={'image-' + i} className="article-image">
              <img src={image.src} alt={image.alt} />
              <div className="caption-credit">
                {image.caption && (
                  <div className="caption">{image.caption}</div>
                )}
                {image.credit && <div className="credit">{image.credit}</div>}
              </div>
            </div>
          );
        })}
      </div>
    );
  }, []);

  const renderArticleSectionReferences = useCallback(
    (references: ArticleReference[]) => {
      if (references.length <= 3) {
        return (
          <div className="article-section-references">
            {references.map((reference, i) => (
              <a
                href={reference.url}
                target="_blank"
                rel="noreferrer"
                key={'reference-' + i}
                className="reference"
              >
                <div className="reference_link">
                  <ExternalLink />
                </div>
                <div className="spacer">|</div>
                <div className="reference_top">
                  {reference.title !== 'No title found' ? (
                    <div className="title">{parse(reference.title)}</div>
                  ) : (
                    <div className="title"></div>
                  )}
                </div>
                <div className="reference_bottom">
                  <span className="journal">{parse(reference.journal)}</span> |{' '}
                  {reference.year}
                </div>
              </a>
            ))}
          </div>
        );
      } else {
        return (
          <div className="article-section-references">
            <div className="preview-row">
              {references.slice(0, 3).map((reference, i) => (
                <a
                  href={reference.url}
                  target="_blank"
                  rel="noreferrer"
                  key={i + '-' + reference.id}
                  className="reference"
                >
                  <div className="reference_link">
                    <ExternalLink />
                  </div>
                  <div className="spacer">|</div>
                  <div className="reference_top">
                    {reference.title !== 'No title found' ? (
                      <div className="title">{parse(reference.title)}</div>
                    ) : (
                      <div className="title"></div>
                    )}
                  </div>
                  <div className="reference_bottom">
                    <span className="journal">{parse(reference.journal)}</span>{' '}
                    | {reference.year}
                  </div>
                </a>
              ))}
            </div>
            <Accordion className="reference-accordion">
              <AccordionSummary
                aria-controls="references-content"
                id="references-header"
              >
                <div className="button--see-all collapsed">
                  Show All References
                </div>
                <div className="button--see-all expanded">
                  Collapse References
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="article-section-references">
                  {references.slice(3).map((reference, i) => (
                    <a
                      href={reference.url}
                      target="_blank"
                      rel="noreferrer"
                      key={i + '-' + reference.id}
                      className="reference"
                    >
                      <div className="reference_link">
                        <ExternalLink />
                      </div>
                      <div className="spacer">|</div>
                      <div className="reference_top">
                        {reference.title !== 'No title found' ? (
                          <div className="title">{parse(reference.title)}</div>
                        ) : (
                          <div className="title"></div>
                        )}
                      </div>
                      <div className="reference_bottom">
                        <span className="journal">
                          {parse(reference.journal)}
                        </span>{' '}
                        | {reference.year}
                      </div>
                    </a>
                  ))}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        );
      }
    },
    []
  );

  const toggleSectionQAStatus = async (sectionId: string, isQAed: boolean) => {
    if (!activeArticle) return;
    await updateSectionQAStatus(sectionId, { isQAed: isQAed });
    if (loadArticleById && activeArticle) {
      loadArticleById(activeArticle.id);
    }
  };

  const renderLastUpdate = () => {
    if (!activeArticle || !activeArticle.sections.length) return null;
    let date = moment(activeArticle.sections[0].createdAt);
    activeArticle.sections.forEach((section) => {
      if (section.updatedAt) {
        const sectionDate = moment(section.updatedAt);
        if (sectionDate.isAfter(date)) {
          date = sectionDate;
        }
      }
    });
    return <p>Last Updated: {date.format('MMMM DD, YYYY')}</p>;
  };

  const getLinkedVignettes = async () => {
    const response = await fetchArticleVignettes(articleId);
    setLinkedVignettes(response);
  };

  const refreshActiveFlashcard = async (flashcardId: string) => {
    const response = await fetchArticleVignettes(articleId);
    const newActiveFlashcard = response
      .map((vignette: Vignette) => vignette.flashcards)
      .flat()
      .find((flashcard: Flashcard) => flashcard?.id === flashcardId);
    setActiveFlashcard(newActiveFlashcard);
  };

  const getExams = async () => {
    const response = await fetchExams();
    if (response) {
      setAvailableExams(response);
    }
  };

  const compileArticleImages = (article: Article) => {
    const images: ImageType[] = [];
    article.sections.forEach((section) => {
      section.Image.forEach((image) => {
        images.push(image);
      });
    });
    return images;
  };

  const toggleVignetteSuspendStatus = async (
    isSuspended: boolean,
    id: string
  ) => {
    const response = await updateVignetteSuspendStatus(id, {
      isSuspended: isSuspended,
    });
    if (response) {
      getLinkedVignettes();
    }
  };

  const toggleVignetteQAStatus = async (
    vignetteId: string,
    isQAed: boolean
  ) => {
    await setVignetteQAStatus(vignetteId, isQAed);
    getLinkedVignettes();
  };

  const toggleFlashcardQAStatus = async (
    flashcardId: string,
    isQAed: boolean
  ) => {
    await setFlashcardQAStatus(flashcardId, isQAed);
    getLinkedVignettes();
  };

  const toggleFlashcardSuspendStatus = async (
    isSuspended: boolean,
    id: string
  ) => {
    const response = await updateFlashcardSuspendStatus(id, {
      isSuspended: !isSuspended,
    });
    if (response) {
      getLinkedVignettes();
    }
  };

  const handleAddBookmark = async (articleId: string) => {
    if (currentUser) {
      const response = await addBookmark(currentUser.id, articleId);
      if (response) {
        setBookmarks(response);
      }
    }
    enqueueSnackbar('Bookmark added.', {
      autoHideDuration: 3000,
    });
  };

  const copyArticleLink = (articleId: string) => {
    copyToClipboard(window.location.origin + '/library/' + articleId);
    enqueueSnackbar('Link copied to clipboard.', {
      autoHideDuration: 3000,
    });
  };

  const renderToolbar = () => {
    const isNonAuthedUser = !currentUser || currentUser?.isAnon;
    const isBookmarked = bookmarks.some(
      (bookmark) => bookmark.articleId === activeArticle?.id
    );
    return (
      <div className="article-toolbar">
        {!isStudyPanel && (
          <button
            className="button button--secondary button--all-topics"
            onClick={() => {
              setMobileMenuIsOpen(true);
              hapticsImpactLight();
            }}
          >
            <Search />
          </button>
        )}
        {!isBookmarked && !isNonAuthedUser && (
          <button
            className="button button--secondary button--bookmark"
            aria-label="Add Bookmark"
            style={{ marginLeft: 'auto' }}
            onClick={() => {
              hapticsImpactLight();
              handleAddBookmark(articleId);
            }}
          >
            <BookmarkIcon />
          </button>
        )}
        {isBookmarked && !isNonAuthedUser && (
          <button
            className="button button--secondary button--bookmark button--bookmark--remove"
            aria-label="Remove Bookmark"
            style={{ marginLeft: 'auto' }}
            onClick={() => {
              hapticsImpactLight();
              removeBookmark(articleId);
            }}
          >
            <BookmarkIcon />
          </button>
        )}
        <button
          className="button button--secondary button--share"
          aria-label="Copy Article Link"
          onClick={() => {
            hapticsImpactLight();
            copyArticleLink(articleId);
          }}
        >
          <Share />
        </button>
        {currentUser && currentUser?.role === 'admin' && (
          <button
            className="button button--secondary button--edit admin-only"
            aria-label="Edit Article Preview"
            onClick={() => {
              setCurrentArticlePreviewText(activeArticle?.articlePreview || '');
              hapticsImpactLight();
              setIsEditingArticlePreview(true);
            }}
          >
            <Edit3 />
          </button>
        )}
      </div>
    );
  };

  const renderArticlePreview = () => {
    if (!activeArticle) return null;
    if (activeArticle.articlePreview) {
      return (
        <ArticlePreview images={articleImages}>
          {parse(activeArticle.articlePreview)}
        </ArticlePreview>
      );
    }
  };

  return (
    <>
      {!currentUser && activeArticle && (
        <Helmet>
          <title>{activeArticle.title} | Ora AI</title>
          <meta name="description" content="" />
          <meta
            property="og:title"
            content={`${activeArticle.title} | Ora AI`}
          />
        </Helmet>
      )}
      <div className="article">
        <div className="article_body">
          <div className="article_body_center">
            <div className="article_body_center_card">
              {activeArticle && (
                <>
                  <div className="article_body_center_card_content">
                    <h1>{activeArticle.title}</h1>
                    {renderToolbar()}
                    {renderArticlePreview()}
                    {renderArticleSections(activeArticle.sections)}
                  </div>
                  <div className="article_body_center_card_footer">
                    {renderLastUpdate()}
                    {currentUser && (
                      <button
                        className="button button--secondary button--report"
                        onClick={() => setReportArticleModalIsOpen(true)}
                      >
                        <AlertTriangle />
                        Report Article
                      </button>
                    )}
                  </div>
                </>
              )}
              {(!activeArticle || articleLoading) && (
                <div
                  className="article_body_center_card_content"
                  style={{ paddingTop: '1.5rem' }}
                >
                  {renderLoadingFrames()}
                </div>
              )}
              {currentUser?.role === 'admin' && !currentUser?.demoMode && (
                <div className="article_body_center_card_editor">
                  <h2>QBank Questions</h2>
                  {linkedVignettes.map((vignette) => (
                    <div key={vignette.id} className="vignette">
                      <div className="admin-toolbar">
                        <div className="suspend-status m-b-1">
                          {vignette.isSuspended ? (
                            <div className="badge badge--error">Suspended</div>
                          ) : (
                            <div className="badge badge--success">Active</div>
                          )}
                        </div>
                        <button
                          className="button button--edit m-b-1"
                          style={{ marginLeft: '0.25rem' }}
                          onClick={() => {
                            toggleVignetteSuspendStatus(
                              !vignette.isSuspended,
                              vignette.id
                            );
                          }}
                        >
                          {vignette.isSuspended ? (
                            <ToggleLeft />
                          ) : (
                            <ToggleRight />
                          )}
                        </button>
                        <div className="spacer m-b-1">|</div>
                        <div className="qa-status m-b-1">
                          {vignette.isQAed ? (
                            <div className="is-qaed">Is QAed</div>
                          ) : (
                            <div className="is-not-qaed">Is Not QAed</div>
                          )}
                        </div>
                        <button
                          className="button button--edit m-b-1"
                          style={{ marginLeft: '0.25rem' }}
                          onClick={() => {
                            toggleVignetteQAStatus(
                              vignette.id,
                              !vignette.isQAed
                            );
                          }}
                        >
                          {vignette.isQAed ? <X /> : <Check />}
                        </button>
                        <div className="spacer m-b-1">|</div>
                        <button
                          className="uuid-display m-t-0"
                          onClick={() => copyToClipboard(vignette.id)}
                        >
                          <Copy />
                          {vignette.id}
                        </button>
                        <button
                          className="button button--edit m-b-1"
                          onClick={() => {
                            setActiveVignette(vignette);
                            setIsEditingVignette(true);
                          }}
                        >
                          <Edit3 />
                        </button>
                        {vignette.suspendReasons &&
                          vignette.suspendReasons.length > 0 && (
                            <div className="suspend-reasons">
                              {vignette.suspendReasons.map((reason: string) => {
                                return (
                                  <div
                                    key={
                                      vignette.id + '-suspend-reason-' + reason
                                    }
                                  >
                                    {reason}
                                  </div>
                                );
                              })}
                            </div>
                          )}
                      </div>
                      {parse(vignette.contents[0].question)}
                      <div className="answers">
                        {vignette.contents[0].answerOptions.map((answer) => (
                          <div
                            key={vignette.id + '-' + answer.optionLetter}
                            className={
                              answer.optionLetter ===
                              vignette.contents[0].correctAnswer
                                ? 'correct'
                                : ''
                            }
                          >
                            {answer.optionLetter}. {parse(answer.optionText)}
                          </div>
                        ))}
                      </div>
                      <div style={{ marginTop: '1.5rem' }}>
                        {parse(vignette.contents[0].explanation)}
                      </div>
                      <hr />
                      {vignette.flashcards && (
                        <div className="flashcards">
                          <Accordion>
                            <AccordionSummary>
                              <h3>
                                Flashcards
                                <ChevronDown />
                              </h3>
                            </AccordionSummary>
                            <AccordionDetails>
                              {vignette.flashcards.map((flashcard) => (
                                <div key={flashcard.id} className="card">
                                  <div className="admin-toolbar">
                                    <div className="suspend-status m-b-1">
                                      {flashcard.isSuspended ? (
                                        <div className="badge badge--error">
                                          Suspended
                                        </div>
                                      ) : (
                                        <div className="badge badge--success">
                                          Active
                                        </div>
                                      )}
                                    </div>
                                    <button
                                      className="button button--edit m-b-1"
                                      style={{ marginLeft: '0.25rem' }}
                                      onClick={() => {
                                        toggleFlashcardSuspendStatus(
                                          !!flashcard.isSuspended,
                                          flashcard.id
                                        );
                                      }}
                                    >
                                      {flashcard.isSuspended ? (
                                        <ToggleLeft />
                                      ) : (
                                        <ToggleRight />
                                      )}
                                    </button>
                                    <div className="spacer m-b-1">|</div>
                                    <div className="qa-status m-b-1">
                                      {flashcard.isQAed ? (
                                        <div className="is-qaed">Is QAed</div>
                                      ) : (
                                        <div className="is-not-qaed">
                                          Is Not QAed
                                        </div>
                                      )}
                                    </div>
                                    <button
                                      className="button button--edit m-b-1"
                                      style={{ marginLeft: '0.25rem' }}
                                      onClick={() => {
                                        toggleFlashcardQAStatus(
                                          flashcard.id,
                                          !flashcard.isQAed
                                        );
                                      }}
                                    >
                                      {flashcard.isQAed ? <X /> : <Check />}
                                    </button>
                                    <div className="spacer m-b-1">|</div>
                                    <button
                                      className="uuid-display m-t-0"
                                      onClick={() =>
                                        copyToClipboard(flashcard.id)
                                      }
                                    >
                                      <Copy />
                                      {flashcard.id}
                                    </button>
                                    <button
                                      className="button button--edit m-b-1"
                                      onClick={() => {
                                        setActiveFlashcard(flashcard);
                                        setActiveFlashcardVariant(
                                          flashcard.contents[0]
                                        );
                                        setIsEditingFlashcard(true);
                                      }}
                                    >
                                      <Edit3 />
                                    </button>
                                  </div>
                                  <div>{parse(flashcard.contents[0].back)}</div>
                                </div>
                              ))}
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      )}
                    </div>
                  ))}
                  {activeVignette && isEditingVignette && (
                    <EditVignette
                      isEditing={isEditingVignette}
                      setIsEditing={setIsEditingVignette}
                      activeVignette={activeVignette}
                      setKeybindsDisabled={() => {}}
                      availableExams={availableExams}
                    />
                  )}
                  {activeFlashcard &&
                    activeFlashcardVariant &&
                    isEditingFlashcard && (
                      <EditFlashcard
                        isEditing={isEditingFlashcard}
                        setIsEditing={setIsEditingFlashcard}
                        activeFlashcard={activeFlashcard}
                        flashcardId={activeFlashcard.id}
                        availableExams={availableExams}
                        refreshActiveFlashcard={refreshActiveFlashcard}
                      />
                    )}
                </div>
              )}
            </div>
          </div>
          {!isStudyPanel && (
            <div className="article_body_right">
              <JumpToMenu
                headings={headings}
                onClick={scrollToSection}
                loading={jumpLoading}
              />
            </div>
          )}
        </div>
        {activeArticle && (
          <ReportArticle
            activeArticle={activeArticle}
            reportArticleModalIsOpen={reportArticleModalIsOpen}
            setReportArticleModalIsOpen={setReportArticleModalIsOpen}
          />
        )}
      </div>
      {currentUser?.role === 'admin' && (
        <>
          <CustomModal
            open={isEditing}
            onClose={() => {
              setIsEditing(false);
              setCurrentArticleSectionText('');
              setCurrentArticleSectionId('');
            }}
            name={'edit-article-section'}
          >
            <div className="modal_header">
              <h2>Edit Article Section</h2>
            </div>
            <div className="modal_content">
              <form>
                <div>
                  <p className="m-y-0">Section ID: {currentArticleSectionId}</p>
                </div>
                <div className="row">
                  <div className="editor">
                    <label htmlFor="editor--article-section">Content</label>
                    <textarea
                      id="editor--article-section"
                      value={currentArticleSectionText}
                      onChange={handleArticleSectionTextChange}
                      style={{
                        minHeight: '28rem',
                      }}
                    ></textarea>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal_actions">
              <button
                className="button button--secondary button--back"
                onClick={() => {
                  setIsEditing(false);
                  hapticsImpactLight();
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="button button--submit"
                onClick={() => {
                  hapticsImpactLight();
                  submitArticleSectionUpdate();
                }}
              >
                Submit
              </button>
            </div>
          </CustomModal>
          <CustomModal
            open={isEditingArticlePreview}
            onClose={() => {
              setIsEditingArticlePreview(false);
              setCurrentArticlePreviewText('');
            }}
            name={'edit-article-preview'}
          >
            <div className="modal_header">
              <h2>Edit Article</h2>
            </div>
            <div className="modal_content">
              <form>
                <div className="row">
                  <div className="editor">
                    <label htmlFor="editor--article-preview">Preview</label>
                    <textarea
                      id="editor--article-preview"
                      value={currentArticlePreviewText}
                      onChange={handleArticlePreviewTextChange}
                      style={{
                        minHeight: '28rem',
                      }}
                    ></textarea>
                  </div>
                </div>
                <div className="row">
                  <div className="editor">
                    <label htmlFor="editor--topic-yield">Topic Yield</label>
                    <input
                      id="editor--topic-yield"
                      type="number"
                      value={currentTopicYield}
                      onChange={handleTopicYieldChange}
                    ></input>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal_actions">
              <button
                className="button button--secondary button--back"
                onClick={() => {
                  setIsEditingArticlePreview(false);
                  hapticsImpactLight();
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="button button--submit"
                onClick={() => {
                  hapticsImpactLight();
                  submitArticleUpdate();
                }}
              >
                Submit
              </button>
            </div>
          </CustomModal>
        </>
      )}
    </>
  );
};

export default ArticlePage;
