export const flashcardQuantities = {
  'USMLE Step 1': [
    { value: 5000, label: '5,000' },
    { value: 9000, label: '9,000' },
    { value: 15000, label: '15,000' },
  ],
  'USMLE Step 2': [
    { value: 5000, label: '5,000' },
    { value: 9000, label: '9,000' },
    { value: 15000, label: '15,000' },
  ],
  'Neurology Shelf Exam': [
    { value: 600, label: '600' },
    { value: 1800, label: '1,800' },
    { value: 3000, label: '3,000' },
  ],
  'Psychiatry Shelf Exam': [
    { value: 600, label: '600' },
    { value: 1800, label: '1,800' },
    { value: 3000, label: '3,000' },
  ],
  'Surgery Shelf Exam': [
    { value: 800, label: '800' },
    { value: 2400, label: '2,400' },
    { value: 4000, label: '4,000' },
  ],
  'Family Medicine Shelf Exam': [
    { value: 600, label: '600' },
    { value: 1800, label: '1,800' },
    { value: 3000, label: '3,000' },
  ],
  'OBGYN Shelf Exam': [
    { value: 800, label: '800' },
    { value: 2400, label: '2,400' },
    { value: 4000, label: '4,000' },
  ],
  'Emergency Medicine Shelf Exam': [
    { value: 600, label: '600' },
    { value: 1800, label: '1,800' },
    { value: 3000, label: '3,000' },
  ],
  'Internal Medicine Shelf Exam': [
    { value: 1400, label: '1,400' },
    { value: 4200, label: '4,200' },
    { value: 7000, label: '7,000' },
  ],
  'Pediatrics Shelf Exam': [
    { value: 800, label: '800' },
    { value: 2400, label: '2,400' },
    { value: 4000, label: '4,000' },
  ],
} as const;

export const qbankQuantities = {
  'USMLE Step 1': [
    { value: 1800, label: '1,800' },
    { value: 2500, label: '2,500' },
    { value: 4500, label: '4,500' },
  ],
  'USMLE Step 2': [
    { value: 1800, label: '1,800' },
    { value: 2500, label: '2,500' },
    { value: 4500, label: '4,500' },
  ],
  'Neurology Shelf Exam': [
    { value: 120, label: '120' },
    { value: 360, label: '360' },
    { value: 600, label: '600' },
  ],
  'Psychiatry Shelf Exam': [
    { value: 120, label: '120' },
    { value: 360, label: '360' },
    { value: 600, label: '600' },
  ],
  'Surgery Shelf Exam': [
    { value: 180, label: '180' },
    { value: 540, label: '540' },
    { value: 900, label: '900' },
  ],
  'Family Medicine Shelf Exam': [
    { value: 120, label: '120' },
    { value: 360, label: '360' },
    { value: 600, label: '600' },
  ],
  'OBGYN Shelf Exam': [
    { value: 180, label: '180' },
    { value: 540, label: '540' },
    { value: 900, label: '900' },
  ],
  'Emergency Medicine Shelf Exam': [
    { value: 120, label: '120' },
    { value: 360, label: '360' },
    { value: 600, label: '600' },
  ],
  'Internal Medicine Shelf Exam': [
    { value: 400, label: '400' },
    { value: 1200, label: '1,200' },
    { value: 2000, label: '2,000' },
  ],
  'Pediatrics Shelf Exam': [
    { value: 180, label: '180' },
    { value: 540, label: '540' },
    { value: 900, label: '900' },
  ],
} as const;

type ExamName = keyof typeof flashcardQuantities;
type QuantityIndex = 0 | 1 | 2; // minimum, recommended, maximum

export const getFlashcardQuantity = (
  examName: string | undefined,
  index: QuantityIndex
): number => {
  const exam = (examName as ExamName) || 'USMLE Step 1';
  return flashcardQuantities[exam][index].value;
};

export const getQbankQuantity = (
  examName: string | undefined,
  index: QuantityIndex
): number => {
  const exam = (examName as ExamName) || 'USMLE Step 1';
  return qbankQuantities[exam][index].value;
};

// Convenience functions for specific quantities
export const getHighYieldFlashcards = (examName?: string) =>
  getFlashcardQuantity(examName, 0);
export const getStandardFlashcards = (examName?: string) =>
  getFlashcardQuantity(examName, 1);
export const getComprehensiveFlashcards = (examName?: string) =>
  getFlashcardQuantity(examName, 2);

export const getHighYieldQbank = (examName?: string) =>
  getQbankQuantity(examName, 0);
export const getStandardQbank = (examName?: string) =>
  getQbankQuantity(examName, 1);
export const getComprehensiveQbank = (examName?: string) =>
  getQbankQuantity(examName, 2);

export const defaultFlashcards = (examName?: string) =>
  getHighYieldFlashcards(examName);
export const defaultVignettes = (examName?: string) =>
  getHighYieldQbank(examName);
