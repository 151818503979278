export const OraMuiMenu = {
  styleOverrides: {
    paper: {
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      color: 'var(--type-body-inverse)',
      backdropFilter: 'blur(20px)',
      WebkitBackdropFilter: 'blur(20px)',
    },
  },
};
