import { useSnackbar } from 'notistack';
import { useRefreshStudySessionAndContent } from './refreshStudySession';
import { resetStudyProgress } from '../services/study';

export const useHandleResetProgress = () => {
  const { refreshStudySessionAndContent } = useRefreshStudySessionAndContent();
  const { enqueueSnackbar } = useSnackbar();

  return async () => {
    try {
      const response = await resetStudyProgress();
      if (response.success) {
        // Refresh today's study session and content
        await refreshStudySessionAndContent();

        enqueueSnackbar('User progress reset successfully.', {
          autoHideDuration: 3000,
        });
      } else {
        enqueueSnackbar('Error resetting user progress.');
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Error resetting user progress.');
    }
  };
};
