export const workloadOptionExamplesMap = {
  'USMLE Step 1': {
    'High Yield': 'HIV, heart failure, stroke',
    Standard: 'Renal tubular acidosis, polymyositis, Blastomycosis',
    Comprehensive: "Behçet's disease, enchondroma, strychnine poisoning",
  },
  'USMLE Step 2': {
    'High Yield': 'HIV, heart failure, stroke',
    Standard: 'Renal tubular acidosis, polymyositis, Blastomycosis',
    Comprehensive: "Behçet's disease, enchondroma, strychnine poisoning",
  },
  'Internal Medicine Shelf Exam': {
    'High Yield': 'HIV, heart failure, stroke',
    Standard: 'Renal tubular acidosis, polymyositis, Blastomycosis',
    Comprehensive: "Behçet's disease, enchondroma, strychnine poisoning",
  },
  'Emergency Medicine Shelf Exam': {
    'High Yield': 'Pneumothorax, stroke, appendicitis',
    Standard: 'Korsakoff Syndrome, retinal detachment, mumps',
    Comprehensive: 'Talus fracture, hydrocele, strychnine poisoning',
  },
  'Family Medicine Shelf Exam': {
    'High Yield': 'Asthma, diabetes, HIV',
    Standard: 'Asbestosis, blepharitis, menopause',
    Comprehensive: 'Bruxism, Iliotibial band syndrome, proctalgia fugax',
  },
  'OBGYN Shelf Exam': {
    'High Yield': 'Preeclampsia, PCOS, molar pregnancy',
    Standard: "Bartholin's cyst, Mittelschmerz, breast cysts",
    Comprehensive: 'Transfusion reactions, pseudocyesis, Phyllodes tumor',
  },
  'Neurology Shelf Exam': {
    'High Yield': "Stroke, meningitis, Alzheimer's",
    Standard: "Bell's palsy, tethered cord, MSA",
    Comprehensive: "Bulbar palsy, McArdle's disease, paraganglioma",
  },
  'Pediatrics Shelf Exam': {
    'High Yield': 'Jaundice, cystic fibrosis, asthma',
    Standard: 'Kawasaki disease, febrile seizure, diaphragmatic hernia',
    Comprehensive: 'Megaureter, Kallmann syndrome, pityriasis alba',
  },
  'Psychiatry Shelf Exam': {
    'High Yield': 'Depression, schizophrenia, ADHD',
    Standard: 'Dysthymia, stimulant use disorder, specific phobia',
    Comprehensive: 'Gambling disorder, trichotillomania, pyromania',
  },
  'Surgery Shelf Exam': {
    'High Yield': 'Appendicitis, hernia, trauma',
    Standard: 'Malignant hyperthermia, flail chest, cauda equina syndrome',
    Comprehensive: 'Noonan syndrome, Mucormycosis, latex allergy',
  },
};
