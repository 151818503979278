import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip as ChartTooltip,
  Legend,
  Filler,
} from 'chart.js';
import moment from 'moment';
import { debounce } from 'lodash';
import useWindowWidth from '../../../hooks/useWindowWidth';
import useWindowHeight from '../../../hooks/useWindowHeight';
import Skeleton from '@mui/material/Skeleton';
import PlaceholderCurve from '../../../assets/images/placeholderCurve.png';
import { ArrowRight } from 'react-feather';
import { DataPoint } from '../../../types/Stats';
import { hapticsImpactLight } from '../../../utils/haptics';
// import { fakeData } from '../../../utils/qbankFakeData';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  ChartTooltip,
  Legend,
  Filler
);

interface QBankPerformanceProps {
  data: DataPoint[] | null;
  showPreviousBlocks: () => void;
}

const QBankPerformance: React.FC<QBankPerformanceProps> = ({
  data,
  showPreviousBlocks,
}) => {
  // data = fakeData; // Uncomment for Demo Data

  const chartRef = useRef<ChartJS<'line'> | null>(null);

  const windowWidth = useWindowWidth();
  const windowHeight = useWindowHeight();

  const [chartKey, setChartKey] = useState(0); // Used to force unmount & remount

  // Debounced resize handler (runs only when resizing stops)
  const handleResize = useCallback(
    debounce(() => {
      if (chartRef.current) {
        chartRef.current.destroy(); // Destroy chart instance safely
      }
      setChartKey((prevKey) => prevKey + 1); // Change key to force remount
    }, 300),
    []
  );

  // Runs the debounced resize effect
  useEffect(() => {
    handleResize(); // Call debounced resize function
  }, [windowWidth, windowHeight, handleResize]);

  const sortedData = data ? [...data] : [];
  sortedData.sort(
    (a, b) => moment(a.date).valueOf() - moment(b.date).valueOf()
  );

  // Create labels, scores, and pointRadius arrays.
  // If there's exactly one data point, add a blank at the start and end.
  let labels: string[] = [];
  let scores: number[] = [];
  let pointRadius: number[] = [];

  if (sortedData.length === 1) {
    const singleLabel = sortedData[0].isDiagnostic
      ? 'Diagnostic'
      : moment(sortedData[0].date).format('MMMM D, YYYY');
    labels = ['', singleLabel, ''];
    scores = [sortedData[0].score, sortedData[0].score, sortedData[0].score];
    pointRadius = [0, 3, 0]; // Hide the fake points, only show the actual point
  } else {
    labels = sortedData.map((dp) =>
      dp.isDiagnostic ? 'Diagnostic' : moment(dp.date).format('MMMM D, YYYY')
    );
    scores = sortedData.map((dp) => dp.score);
    pointRadius = new Array(scores.length).fill(3);
  }

  const lineData = {
    labels: labels,
    datasets: [
      {
        label: 'Score',
        data: scores,
        borderColor: '#FEFEFE',
        borderWidth: 1,
        pointRadius: pointRadius,
        backgroundColor: 'transparent',
        fill: true,
        tension: 0.4,
        pointBackgroundColor: '#FEFEFE',
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false, // Ensures dynamic resizing
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          label: function (context: any) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.raw !== null) {
              label += context.raw + '%';
            }
            return label;
          },
        },
      },
    },
    scales: {
      x: {
        grid: { display: false },
        ticks: {
          display: windowWidth < 560 ? false : true,
          color: '#FEFEFE',
          font: {
            family: 'Poppins',
          },
        },
        border: {
          color: '#FEFEFE',
        },
      },
      y: {
        beginAtZero: true,
        min: 0,
        max: 100,
        grid: { display: false },
        ticks: {
          stepSize: 10,
          color: '#FEFEFE',
          font: {
            family: 'Poppins',
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          callback: function (value: any) {
            return value + '%';
          },
        },
        border: {
          color: '#FEFEFE',
        },
      },
    },
    animation: {
      onComplete: () => {
        const chart = chartRef.current;
        if (chart) {
          const ctx = chart.ctx;
          const gradient = ctx.createLinearGradient(
            0,
            0,
            0,
            chart.chartArea.bottom
          );
          gradient.addColorStop(0, 'rgba(255, 255, 255, 0.5)');
          gradient.addColorStop(1, 'rgba(255, 255, 255, .02)');
          chart.data.datasets[0].backgroundColor = gradient;
          chart.update();
        }
      },
    },
  };

  return (
    <div className="block block--dashboard qbank-performance">
      <div className="block_body">
        <h2 className="m-t-0">QBank Performance</h2>
        {!data && (
          <Skeleton
            sx={{
              bgcolor: 'rgba(255,255,255,.05)',
              borderRadius: '1rem',
            }}
            variant="rectangular"
            animation="wave"
            width={'100%'}
            height={'100%'}
          />
        )}
        <div className="chart-container" style={{ height: '50vh' }}>
          {data && data.length > 0 ? (
            <Line
              key={chartKey}
              data={lineData}
              options={chartOptions}
              ref={chartRef}
            />
          ) : (
            <div className="no-data">
              QBank performance will appear here.
              <img src={PlaceholderCurve} alt="" />
            </div>
          )}
        </div>
        {data && data.length > 0 && (
          <button
            style={{ display: 'flex', marginLeft: 'auto', padding: 0 }}
            className="button button--link--glass m-t-1"
            onClick={() => {
              showPreviousBlocks();
              hapticsImpactLight();
            }}
          >
            Review Previous Blocks
            <ArrowRight />
          </button>
        )}
      </div>
    </div>
  );
};

export default QBankPerformance;
