import React, { useEffect, useRef, useState } from 'react';
import ThreeOrb from '../../../components/Home/ThreeOrb';

interface ScreensaverProps {
  isIdle: boolean;
  screenSaverDisplay: string;
  learningObjectives: string[];
}

const Screensaver: React.FC<ScreensaverProps> = ({
  isIdle,
  screenSaverDisplay,
  learningObjectives,
}) => {
  const [loIndex, setLoIndex] = useState(0);
  const [barAnimationKey, setBarAnimationKey] = useState(0);
  const [barAnimationActive, setBarAnimationActive] = useState(false);
  const [showLearningObjectives, setShowLearningObjectives] = useState(false);

  const loNumberRef = useRef<HTMLDivElement>(null);
  const loTextRef = useRef<HTMLDivElement>(null);
  const barRef = useRef<HTMLDivElement>(null);

  const showObjectivesTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(
    null
  );
  const streamingIntervalRef = useRef<ReturnType<typeof setInterval> | null>(
    null
  );
  const barTimerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  /**
   * Clean up all pending streams, intervals, or timeouts.
   */
  const cleanupStreams = () => {
    if (streamingIntervalRef.current) {
      clearInterval(streamingIntervalRef.current);
      streamingIntervalRef.current = null;
    }
    if (barTimerRef.current) {
      clearTimeout(barTimerRef.current);
      barTimerRef.current = null;
    }
    if (loNumberRef.current) loNumberRef.current.textContent = '';
    if (loTextRef.current) loTextRef.current.textContent = '';
    setBarAnimationActive(false);
  };

  /**
   * Streams text character by character into a target div.
   */
  const animateTextStreaming = (
    text: string,
    textTargetRef: React.RefObject<HTMLDivElement>,
    speed = 20,
    onFinish?: () => void
  ) => {
    if (!textTargetRef.current) return null;
    textTargetRef.current.textContent = '';
    let idx = 0;

    const intervalId = setInterval(() => {
      if (!textTargetRef.current) return;
      textTargetRef.current.textContent += text.charAt(idx);
      idx++;
      if (idx >= text.length) {
        clearInterval(intervalId);
        onFinish?.();
      }
    }, speed);

    return intervalId;
  };

  /**
   * Decide when to show or hide learning objectives based on idle state and LO availability.
   */
  useEffect(() => {
    // If there are no LOs at all, clear everything and skip.
    if (learningObjectives.length === 0) {
      cleanupStreams();
      setShowLearningObjectives(false);
      return;
    }

    // If the user isn't idle, reset everything.
    if (!isIdle) {
      cleanupStreams();
      setShowLearningObjectives(false);
      return;
    }

    // Otherwise, user is idle and we do have LOs:
    // Wait 5s, then show them. (Store this in a ref so we can clear if needed.)
    showObjectivesTimeoutRef.current = setTimeout(() => {
      setShowLearningObjectives(true);
    }, 5000);

    return () => {
      if (showObjectivesTimeoutRef.current) {
        clearTimeout(showObjectivesTimeoutRef.current);
        showObjectivesTimeoutRef.current = null;
      }
    };
  }, [isIdle, learningObjectives]);

  /**
   * Handle the text streaming for LOs once we're idle, LOs exist, and we've decided to show them.
   */
  useEffect(() => {
    if (
      learningObjectives.length === 0 ||
      !isIdle ||
      !showLearningObjectives ||
      !learningObjectives[loIndex]
    ) {
      return;
    }

    const numberIntervalId = animateTextStreaming(
      `Learning Objective #${loIndex + 1}`,
      loNumberRef,
      20,
      () => {
        // After we finish streaming the "Learning Objective #x" text:
        const textIntervalId = animateTextStreaming(
          learningObjectives[loIndex],
          loTextRef,
          20,
          () => {
            // Once the LO text is fully streamed, start the progress bar timing.
            // 1) Force a fresh mount of the bar so animation restarts from zero.
            setBarAnimationKey((prev) => prev + 1);
            setBarAnimationActive(false);

            // 2) Allow the DOM to handle .active from 0 → 100%
            requestAnimationFrame(() => {
              setBarAnimationActive(true);
            });

            // 3) After 15s, clear and pick a random LO to show next.
            const timerId = setTimeout(() => {
              setBarAnimationActive(false);
              if (loNumberRef.current) loNumberRef.current.textContent = '';
              if (loTextRef.current) loTextRef.current.textContent = '';
              setLoIndex(Math.floor(Math.random() * learningObjectives.length));
            }, 15000);

            barTimerRef.current = timerId;
          }
        );
        streamingIntervalRef.current = textIntervalId;
      }
    );
    streamingIntervalRef.current = numberIntervalId;

    // If we leave or re-run the effect, clean up intervals.
    return () => {
      if (numberIntervalId) clearInterval(numberIntervalId);
      if (streamingIntervalRef.current)
        clearInterval(streamingIntervalRef.current);
    };
  }, [learningObjectives, loIndex, isIdle, showLearningObjectives]);

  /**
   * If the LOs list changes, pick a random one to start with next time.
   */
  useEffect(() => {
    if (learningObjectives.length > 0) {
      setLoIndex(Math.floor(Math.random() * learningObjectives.length));
    }
  }, [learningObjectives]);

  return (
    <div
      className={`screensaver
        ${isIdle ? 'in' : 'out'}
        ${learningObjectives.length === 0 ? 'no-los' : ''}
        ${
          showLearningObjectives && learningObjectives.length > 0
            ? 'show-learning-objectives'
            : ''
        }`}
      style={{ display: screenSaverDisplay }}
    >
      <div className="screensaver_inner">
        {/* Always show the orb regardless of LO presence */}
        <ThreeOrb isScreensaver={true} />

        {/* Only show text / bar if there are LOs */}
        {learningObjectives.length > 0 && (
          <div className="screensaver-content">
            <div className="learning-objective-number" ref={loNumberRef} />
            <div className="learning-objective-text" ref={loTextRef} />
            <div
              className={`bar-timer-container ${barAnimationActive ? 'show' : ''}`}
            >
              <div
                key={barAnimationKey}
                ref={barRef}
                className={`bar-timer ${barAnimationActive ? 'active' : ''}`}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Screensaver;
