import { CSSObject } from '@mui/material/styles';

export const OraMuiToggleButton = {
  styleOverrides: {
    root: {
      textTransform: 'none',
      borderColor: 'transparent',
      backgroundColor: 'var(--surface-glass-light)',
      fontFamily: 'var(--font-family-heading)',
      fontWeight: 500,
      borderRadius: '0.5rem',
      '&:hover': {
        backgroundColor: 'var(--surface-glass-light-focus)',
      },
      '&.Mui-selected': {
        color: '#FEFEFE',
        backgroundColor: '#6CC2B2',
        '&:hover': {
          color: '#FEFEFE',
          backgroundColor: '#6CC2B2',
        },
      },
    } as CSSObject,
  },
  variants: [],
};
