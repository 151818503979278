export const OraMuiSelect = {
  styleOverrides: {
    root: {
      color: 'var(--type-body-inverse)',
      borderRadius: '0.5rem',
      '&.Mui-disabled': {
        color: 'var(--type-body-inverse)',
        WebkitTextFillColor: 'var(--type-body-inverse)',
        opacity: 0.5,
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'var(--surface-glass-light) !important',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          backgroundColor: 'var(--surface-glass-light) !important',
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'var(--surface-glass-light)',
        backgroundColor: 'var(--surface-glass-light)',
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
        backgroundColor: 'var(--surface-glass-light-focus)',
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent !important',
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent !important',
      },
    },
    select: {
      svg: {
        display: 'none',
      },
      '&.Mui-disabled': {
        color: 'var(--type-body-inverse) !important',
        WebkitTextFillColor: 'var(--type-body-inverse) !important',
        opacity: 1,
      },
    },
    icon: {
      color: 'var(--type-body-inverse)',
      '&.Mui-disabled': {
        opacity: 0.5,
      },
    },
  },
};
