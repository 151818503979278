export const OraMuiSpeedDial = (buttonColor: string) => {
  return {
    styleOverrides: {
      root: {
        zIndex: 1,
      },
      fab: {
        color: buttonColor,
        boxShadow: 'none',
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: 'transparent',
        },
        '&:focus': {
          boxShadow: 'none',
        },
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
  };
};

export const OraMuiSpeedDialAction = (buttonColor: string) => {
  return {
    styleOverrides: {
      fab: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        color: buttonColor,
        '&:hover': {
          backgroundColor: 'transparent',
        },
        '&:focus': {
          boxShadow: 'none',
          backgroundColor: 'transparent',
        },
        '&:active': {
          boxShadow: 'none',
          backgroundColor: 'transparent',
        },
      },
    },
  };
};
