import { useSnackbar } from 'notistack';
import OraSwitch from '../../CustomMUI/Switch';
import {
  getHighYieldFlashcards,
  getHighYieldQbank,
  getStandardFlashcards,
  getStandardQbank,
  getComprehensiveFlashcards,
  getComprehensiveQbank,
} from '../../../utils/contentQuantityUtils';
import { hapticsImpactLight } from '../../../utils/haptics';
import { workloadOptionExamplesMap } from '../../../utils/workloadOptionExamples';

interface ContentSelectionProps {
  contentSelectionOption: 'High Yield' | 'Standard' | 'Comprehensive' | '';
  primaryExamName: string;
  primaryExamDate: string;
  isUsingFlashcards: boolean;
  setIsUsingFlashcards: (value: boolean) => void;
  totalUserSelectedFlashcards: number;
  setTotalUserSelectedFlashcards: (value: number) => void;
  isUsingQbank: boolean;
  setIsUsingQbank: (value: boolean) => void;
  totalUserSelectedVignettes: number;
  setTotalUserSelectedVignettes: (value: number) => void;
  setWorkloadOption: (value: string) => void;
}

const ContentSelection: React.FC<ContentSelectionProps> = ({
  contentSelectionOption,
  primaryExamName,
  isUsingFlashcards,
  setIsUsingFlashcards,
  isUsingQbank,
  setIsUsingQbank,
  setWorkloadOption,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  return (
    <div className="content-selection">
      <div className="content-selection_switch-container">
        <div className="content-selection_switch-container_switch">
          <OraSwitch
            checked={isUsingQbank}
            onChange={(e) => {
              setIsUsingQbank(e.target.checked);
              if (!isUsingFlashcards) {
                setIsUsingFlashcards(true);
                enqueueSnackbar(
                  'You must have at least one content type enabled.',
                  {
                    autoHideDuration: 3000,
                    style: { width: 'fit-content' },
                  }
                );
              }
            }}
          />
          QBank
        </div>
        <div className="content-selection_switch-container_switch">
          <OraSwitch
            checked={isUsingFlashcards}
            onChange={(e) => {
              setIsUsingFlashcards(e.target.checked);
              if (!isUsingQbank) {
                setIsUsingQbank(true);
                enqueueSnackbar(
                  'You must have at least one content type enabled.',
                  {
                    autoHideDuration: 3000,
                    style: { width: 'fit-content' },
                  }
                );
              }
            }}
          />
          Flashcards
        </div>
      </div>
      <div className="content-selection_options">
        <button
          className={`button button--workload-option ${contentSelectionOption === 'High Yield' ? 'is-selected' : ''}`}
          onClick={() => {
            hapticsImpactLight();
            setWorkloadOption('High Yield');
          }}
        >
          <div className="button--workload-option_name">High Yield</div>
          <div className="button--workload-option_quantity">
            {isUsingQbank &&
              `${getHighYieldQbank(primaryExamName)} QBank questions`}
            {isUsingQbank && isUsingFlashcards && ' | '}
            {isUsingFlashcards &&
              `${getHighYieldFlashcards(primaryExamName)} flashcards`}
          </div>
          <div className="button--workload-option_description">
            Topics for a safe passing score (e.g.{' '}
            {
              workloadOptionExamplesMap[
                primaryExamName as keyof typeof workloadOptionExamplesMap
              ]['High Yield']
            }
            )
          </div>
        </button>
        <button
          className={`button button--workload-option ${contentSelectionOption === 'Standard' ? 'is-selected' : ''}`}
          onClick={() => {
            hapticsImpactLight();
            setWorkloadOption('Standard');
          }}
        >
          <div className="button--workload-option_name">Standard</div>
          <div className="button--workload-option_quantity">
            {isUsingQbank &&
              `${getStandardQbank(primaryExamName)} QBank questions`}
            {isUsingQbank && isUsingFlashcards && ' | '}
            {isUsingFlashcards &&
              `${getStandardFlashcards(primaryExamName)} flashcards`}
          </div>
          <div className="button--workload-option_description">
            Topics for an above-average score (e.g.{' '}
            {
              workloadOptionExamplesMap[
                primaryExamName as keyof typeof workloadOptionExamplesMap
              ]['Standard']
            }
            )
          </div>
        </button>
        <button
          className={`button button--workload-option ${contentSelectionOption === 'Comprehensive' ? 'is-selected' : ''}`}
          onClick={() => {
            hapticsImpactLight();
            setWorkloadOption('Comprehensive');
          }}
        >
          <div className="button--workload-option_name">Comprehensive</div>
          <div className="button--workload-option_quantity">
            {isUsingQbank &&
              `${getComprehensiveQbank(primaryExamName)} QBank questions`}
            {isUsingQbank && isUsingFlashcards && ' | '}
            {isUsingFlashcards &&
              `${getComprehensiveFlashcards(primaryExamName)} flashcards`}
          </div>
          <div className="button--workload-option_description">
            Topics for a peak score (e.g.{' '}
            {
              workloadOptionExamplesMap[
                primaryExamName as keyof typeof workloadOptionExamplesMap
              ]['Comprehensive']
            }
            )
          </div>
        </button>
      </div>
    </div>
  );
};

export default ContentSelection;
