import axiosInstance from './axiosCreator';
import { SchedulingRequestBody } from '../types/Study';
import { cacheFlashcardImages, cacheVignetteImages } from '../utils/imgCaching';
import { enqueueSnackbar } from 'notistack';

interface ReportContentRequest {
  email: string;
  contentId: string;
  contentText: string;
  reason: string;
}

export const fetchTodayStudySession = async () => {
  try {
    const response = await axiosInstance.get('/study/session');
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchStudySessionById = async (sessionId: string) => {
  try {
    const response = await axiosInstance.get(`/study/session/${sessionId}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchYesterdayStudySession = async () => {
  try {
    const response = await axiosInstance.get('/study/yesterday');
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchTodayBlocks = async () => {
  try {
    const response = await axiosInstance.get('/study/blocks');
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchBlocksByStudySessionId = async (sessionId: string) => {
  try {
    const response = await axiosInstance.get(
      `/study/session/${sessionId}/blocks`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchVignettesForBlock = async (blockId: string) => {
  try {
    const response = await axiosInstance.get(
      `/study/blocks/${blockId}/vignettes`
    );
    if (response.data.vignettes && response.data.vignettes.length > 0) {
      cacheVignetteImages(response.data.vignettes);
    }
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchFlashcardsForStudySession = async (sessionId: string) => {
  try {
    const response = await axiosInstance.get(
      `/study/session/${sessionId}/flashcards`
    );
    if (response.data.new) {
      cacheFlashcardImages(response.data.new);
    }
    if (response.data.due) {
      cacheFlashcardImages(response.data.due);
    }
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const updateFlashcardSchedulingBE = async (
  flashcardId: string,
  schedulingObj: SchedulingRequestBody
) => {
  try {
    const response = await axiosInstance.put(
      `/study/flashcards/${flashcardId}/scheduling`,
      schedulingObj
    );
    return response.data;
  } catch (error) {
    enqueueSnackbar(
      'Failed to update flashcard. Please check your network connection or refresh.',
      {
        autoHideDuration: 5000,
        variant: 'error',
      }
    );
    console.error(error);
  }
};

export const reportContent = async (reportInfo: ReportContentRequest) => {
  try {
    const response = await axiosInstance.post(
      '/study/content/report',
      reportInfo
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const resetStudyProgress = async () => {
  try {
    const response = await axiosInstance.post('/study/reset');
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchStudiedContentCounts = async () => {
  try {
    const response = await axiosInstance.get('/study/studiedCounts');
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const modifyStudySession = async (
  studySessionId: string,
  flashcardCount: number,
  vignetteCount: number
) => {
  try {
    const response = await axiosInstance.post(`/study/modifyStudySession`, {
      studySessionId,
      flashcardCount,
      vignetteCount,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
